import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"
import { chartRankingDistrib } from '../components/ranking_distrib'

export default class extends Controller {
  static values = { userId: String, searchBy: String }
  // static subscription = null

  connect() {

    // Call `createConsumer()` to get the consumer instance
    this.subscription = createConsumer().subscriptions.create(
      { channel: "RankingDistributionChannel", user_id: this.userIdValue, search_by: this.searchByValue },
      {
        received: this.handleReceived.bind(this)
      }
    )
  }


  handleReceived(data) {
    const rankingDiv  = document.getElementById(`ranking_distribution_${this.searchByValue}`)
    if(rankingDiv) {
      rankingDiv.innerHTML = data.html
      // this.element.innerHTML = data.html
      chartRankingDistrib();
    }
  }
}
