import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { userId: String }
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {

    if (!this.constructor.subscription) {
      // Call `createConsumer()` to get the consumer instance
      this.constructor.subscription = createConsumer().subscriptions.create(
        { channel: "SectionRemarqueChannel", user_id: this.userIdValue },
        { received: this.handleReceived.bind(this)}
      )
    }
  }

  handleReceived(data) {
    const sectionRemarqueDiv = document.getElementById("section_remarque_dgq")
    if(sectionRemarqueDiv) {
      sectionRemarqueDiv.innerHTML = data.html
    }
  }
}
