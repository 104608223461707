// app/javascript/controllers/tinymce_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (typeof tinymce !== 'undefined') {
      tinymce.init({
        selector: '.tinymce',
        plugins: 'fullscreen link image lists',
        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | fullscreen',
        menubar: false,
        setup: (editor) => {
          editor.on('change', () => {
            editor.save();
          });
        }
      });
    } else {
      console.error("TinyMCE n'est pas chargé sur cette page.");
    }
  }

  disconnect() {
    tinymce.remove();
  }
}
