import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {triggerLoadingDots} from '../components/_loading_dots'

import {escapeSelector} from '../ultilities'

import { courbe_evolution_nps } from '../components/charts/courbe_evolution_nps'
import { courbe_evolution_csat } from '../components/charts/courbe_evolution_csat'
import { donught_csat_theme } from '../components/charts/donught_csat_theme'
import { chartComparisonCsat } from '../components/chart_comparison_csat'
import { chartRankingDistrib } from '../components/ranking_distrib'
import {
					createLabelsArray,
					convertDate,
				} from '../components/charts/utilities'

Chart.defaults.global.animation.duration = 0; // REMOVE THIS LINE TO RE-ACTIVATE THE ANIMATIONS
const initChart = () => {
	// let	shouldAnimate
	// console.log(" sessionStorage.getItem(\"shouldAnimate\")",  sessionStorage.getItem("shouldAnimate"))

	// if (sessionStorage.getItem("shouldAnimate")) {
	// 	shouldAnimate = sessionStorage.getItem("shouldAnimate")
	// } else {
	// 	sessionStorage.setItem("shouldAnimate", "true");
	// 	shouldAnimate = sessionStorage.getItem("shouldAnimate")
	// }
	// // Chart.defaults.global.animation.duration = 0;
	// if (shouldAnimate == "true") {
	// 	Chart.defaults.global.animation.duration = 1000;
	// 	console.log("ANIMATE HERE")
	// } else {
	// 	Chart.defaults.global.animation.duration = 0;
	// 	sessionStorage.setItem("shouldAnimate", "true")
	// 	console.log("set shouldAnimate to true")
	// }


	// document.addEventListener("turbo:before-cache", function() {
	//   // Disable animations when the page is about to be cached
	//   shouldAnimate = false;
	// 	console.log("SHOULD ANIMATE 1 -- FALSE")
	// });

	// document.addEventListener("turbo:render", function() {
	// 	// Enable animations when the cached page is rendered
	// 	sessionStorage.setItem("shouldAnimate", "false")
	// 	shouldAnimate = false
	// 	console.log("set shouldAnimate to false")
	// });

	let courbe_satisfaction_serv = null;
	let note_satisfaction_service = null;
	let comparaison_items = null;
	let courbe_satisfaction_generale = null;
	let note_satisfaction_meal = null;
	let note_satisfaction_enquetes = null;
	let note_satisfaction_generale = null;
	let polar_chart = null;
	let satisfaction_generale_chart = null;
	let evolution_satisfaction_generale = null;
	let recommendationChart = null;
	let comparaison_items_rewards = null;
	let nps_decompo_per_enquete = null;
	let doughnut_recompenses = null;

	const loadingDotsContainer = document.querySelector(".dots-container");

	// Récupérer la date sélectionnée dans le datepicker, stockée dans la variable date
	const dateTag = document.getElementById("date");

	const resto = document.querySelector(".resto");
	if (resto) {
		const group = document.querySelector(".resto").dataset.group
		const sg = document.querySelector(".resto").dataset.sg
	}

	const splitLabel = (label, maxLength) => {
		if (label.length <= maxLength) return [label];
		const words = label.split(' ');
		const lines = [];
		let currentLine = '';

		words.forEach(word => {
			if (currentLine.length + word.length + 1 <= maxLength) {
				currentLine += (currentLine ? ' ' : '') + word;
			} else {
				lines.push(currentLine);
				currentLine = word;
			}
		});

		if (currentLine) lines.push(currentLine);
		return lines;
	};

	const locale = window.location.href.match(/\/en\//) ? 'en' : 'fr'

	if (dateTag) {

		let date = new Date(convertDate(dateTag.value));

		console.log(date)

		// Récuperer la classe active (semaine, jour, mois, etc.)
		let locale = "fr"
		let format = "cumul"
		if (document.querySelector(".nav-filter")) {
			locale = document.querySelector(".nav-filter").dataset.locale
		}
		if (document.getElementById("date-data")) {
			format = document.getElementById("date-data").dataset.timeframe
		}
		let labelUn = "test"
		let labelDeux = "test"
    let improvement = "Amélioration possible"
		console.log(format)

		if (locale == "fr") {
			if (format === "day") {
				labelUn = "Aujourd'hui ";
				labelDeux = "Hier ";
			}
			else if (format === "week") {
				labelUn = "Cette semaine ";
				labelDeux = "La semaine dernière ";
			}
			else if (format === "month") {
				labelUn = "Ce mois-ci ";
				labelDeux = "Le mois dernier ";
			}
			else if (format === "year") {
				labelUn = "Cette année ";
				labelDeux = "L'année dernière ";
			}
			else if (format === "cumul") {
				labelUn = "Cumul ";
				labelDeux = " ";
			}
			else {
				labelUn = "Cette periode";
				labelDeux = "Periode précédente";
			}
		} else if (locale == "en") {
			if (format === "day") {
				labelUn = "Today ";
				labelDeux = "Yesterday ";
			}
			else if (format === "week") {
				labelUn = "This week ";
				labelDeux = "Last week ";
			}
			else if (format === "month") {
				labelUn = "This month ";
				labelDeux = "Last month ";
			}
			else if (format === "year") {
				labelUn = "This year ";
				labelDeux = "Last year ";
			}
			else if (format === "cumul") {
				labelUn = "Cumul ";
				labelDeux = " ";
			}
			else {
				labelUn = "Cette periode";
				labelDeux = "Periode précédente";
			}
      improvement = "Possible improvement"
		}

		//Méthode qui remplace les points de chaque élément d'un tableau par un espace
		const removeDots = (array) => {
			return array.map(word => word.split(".").join(" "));
		};




		//Graphe de l'évolution de la satisfaction client
		// BAR EVOLUTION J, J-1, J-2, ...
		//
    courbe_evolution_csat();

		// const evolutionNPS = document.getElementById("courbe-nps");
		// if (evolutionNPS) {
    //   console.log("courbe_nps");
		// 	const evolution_nps = new Chart(evolutionNPS, {
		// 		type: 'bar',
		// 		data: {
		// 			labels: createLabelsArray(date),
		// 			datasets: [{
		// 				label: 'NPS ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
		// 				data: JSON.parse(evolutionNPS.dataset.points),
		// 				backgroundColor: [
		// 					'#e5e7f1',
		// 					'#e5e7f1',
		// 					'#e5e7f1',
		// 					'#e5e7f1',
		// 					'#e5e7f1',
		// 					'#e5e7f1',
		// 					setBackgroundColorForNPS(evolutionNPS.dataset.points),
		// 				],
		// 				hoverBackgroundColor: setHoverColorForNPS(evolutionNPS.dataset.points),
		// 				borderWidth: 1
		// 			}]
		// 			// pour fixer le gadriage et les axes du graphe
		// 		},
		// 		options: {
		// 			legend: {
		// 				display: false
		// 			},
		// 			scales: {
		// 				xAxes: [{
		// 					gridLines: {
		// 						display: false
		// 					},
		// 				}],
		// 				yAxes: [{
		// 					ticks: {
		// 						display: true,
		// 						min: -100,
		// 						max: 100
		// 					},
		// 					gridLines: {
		// 						drawBorder: false,
		// 						display: false
		// 					},
		// 				}]
		// 			},
		// 			plugins: {
		// 				datalabels: {
		// 					anchor: 'end',
		// 					align: function (context) {
		// 						return context.dataset.data[context.dataIndex] < 8 ? 'top' : 'start';
		// 					},
		// 					color: function (context) {
		// 						return context.dataset.data[context.dataIndex] < 8 ? ['#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff'] : ['#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', 'white'];
		// 					},
		// 				},
		// 			}
		// 		},

		// 	});

		// 	let chart = evolution_nps
		// 	chart.options.animation = false;

		// 	console.log(evolution_nps.data.labels);
		// 	if ((evolution_nps.data.labels[0] == 'NaN undefined') || (evolution_nps.data.labels[0] == 'S NaN/NaN') || (evolution_nps.data.labels[0] == 'undefined NaN') || `${evolution_nps.data.labels[0]}` == 'NaN') {
		// 		location.reload();
		// 	}

		// 	// ONCLICK FT evol NPS
		// 	// evolutionNPS.onclick = function (e) {
		// 	// 	let section = evolution_nps.getElementAtEvent(e);
		// 	// 	if (!section.length) return; // return if not clicked on section
		// 	// 	let label = section[0]._model.label;

		// 	// 	// add case for each label/section
		// 	// 	let i = 6;
		// 	// 	evolution_nps.data.labels.forEach(function (element) {
		// 	// 		const titre = document.getElementById("title-nps").innerText;
		// 	// 		if (label === element) {
		// 	// 			if (titre === "ÉVOLUTION DU NPS") {
		// 	// 				const resto = document.querySelector(".resto");
		// 	// 				if (resto) {
		// 	// 					const group = resto.dataset.group
		// 	// 					const sg = resto.dataset.sg
		// 	// 					switch (format) {
		// 	// 						case 'Jour':
		// 	// 						case 'Day':
		// 	// 							window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day_nps?date=${new Date(date.setDate(date.getDate() - i))}`, '_self');
		// 	// 							date = new Date(convertDate(dateTag.value));
		// 	// 							break;
		// 	// 						case 'Semaine':
		// 	// 						case 'Week':

		// 	// 							window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/week_nps?date=${new Date(date.setDate(date.getDate() - (7 * i)))}`, '_self');
		// 	// 							date = new Date(convertDate(dateTag.value));
		// 	// 							break;
		// 	// 						case 'Mois':
		// 	// 						case 'Month':

		// 	// 							window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/month_nps?date=${new Date(date.setMonth(date.getMonth() - i))}`, '_self');
		// 	// 							date = new Date(convertDate(dateTag.value));
		// 	// 							break;
		// 	// 						case 'Année':
		// 	// 						case 'Year':

		// 	// 							window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/year_nps?date=${new Date(date.setYear(date.getFullYear() - i))}`, '_self');
		// 	// 							date = new Date(convertDate(dateTag.value));
		// 	// 							break;
		// 	// 						case 'Cumul':
		// 	// 						case 'Cumul':

		// 	// 							window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/cumul_nps?date=${new Date(date.setYear(date.getFullYear() - i))}`, '_self');
		// 	// 							date = new Date(convertDate(dateTag.value));
		// 	// 							break;
		// 	// 						default:

		// 	// 							window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day_nps?date=${new Date(date.setDate(date.getDate() - i))}`, '_self');
		// 	// 							date = new Date(convertDate(dateTag.value));
		// 	// 					}
		// 	// 				}

		// 	// 			}

		// 	// 		}
		// 	// 		i = i - 1;
		// 	// 	})
		// 	// 	// add rests ...
		// 	// }
		// }

    courbe_evolution_nps();

    donught_csat_theme();

		// // Graphes douhnuts Satisfaction generale, qualité des plats, qualité du service
		// //  DOUGNUTS CARTE SERVICE GENERAL

		// const all_doughnuts = document.querySelectorAll(".satisfaction_generale_chart");
		// if (all_doughnuts) {
		// 	console.log("satisfaction_generale_chart")
		// 	Array.prototype.forEach.call(all_doughnuts, function (doughnut) {
		// 			var back_color = '#5d78ff';
		// 			var hover_color = 'rgb(81,138,268)';
		// 			var hover_border_color = 'rgb(81,138,268)';
		// 			if (doughnut.getAttribute('id') == 'csat-pos') {
		// 				back_color = '#1dc9b7';
		// 				hover_color = '#46D2C4';
		// 				hover_border_color = '#46D2C4';
		// 			} else if (doughnut.getAttribute('id') == 'csat-neg') {
		// 				back_color = '#fd397a';
		// 				hover_color = '#fd397a';
		// 				hover_border_color = '#fd397a';
		// 			} else if (doughnut.getAttribute('id') == 'csat-neutral') {
		// 				back_color = '#ffb822';
		// 				hover_color = '#FFCB5E';
		// 				hover_border_color = '#FFCB5E';
		// 			}

    //     var labels_by_type_of_graph = ["Note", "Amélioration possible"]
    //     if (doughnut.id.includes("csat")) {
    //       labels_by_type_of_graph = ["CSAT", "Amélioration possible"]
    //     }

    //     satisfaction_generale_chart = new Chart(doughnut, {
		// 			type: 'doughnut',
		// 			data: {
		// 				labels: labels_by_type_of_graph,
		// 				datasets: [{
		// 					datalabels: {
		// 						display: false,
		// 					},
		// 					data: JSON.parse(doughnut.dataset.points),

		// 					backgroundColor: [
		// 						back_color,
		// 						'#e5e7f1',
		// 					],

		// 					hoverBackgroundColor: [
		// 						hover_color,
		// 						'rgb(248,233,260)',
		// 					],

		// 					hoverBorderColor: [
		// 						hover_border_color,
		// 						'rgb(248,233,260)',
		// 					],
		// 					borderWidth: 1
		// 				}],

		// 			},
		// 			options: {
		// 				cutoutPercentage: 80,
		// 				legend: {
		// 					display: false
		// 				},
		// 			},
		// 		});

		// 		let chart = satisfaction_generale_chart
		// 		chart.options.animation = false;

		// 		doughnut.onmouseover = function (e) {
		// 			let compteur = doughnut.dataset.compteur;
		// 			if (compteur === "1" || compteur === "2") {
		// 				document.body.style.cursor = "pointer";
		// 			}
		// 		}

		// 		doughnut.onmouseout = function (e) {
		// 			let compteur = doughnut.dataset.compteur;
		// 			if (compteur === "1" || compteur === "2") {
		// 				document.body.style.cursor = "";
		// 			}
		// 		}

		// 		doughnut.onclick = function () {
		// 			// OLD ONCLICK HANDLE:
		// 				// let compteur = doughnut.dataset.compteur;
		// 				// const ids = parseInt(doughnut.dataset.points_id);
		// 				// const group = document.querySelector('.resto').dataset.group
		// 				// const sg = document.querySelector(".resto").dataset.sg

		// 				// if (compteur === "1") {
		// 				// 	switch (format) {
		// 				// 		case 'Jour':
		// 				// 		case 'Day':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/meal_items/${locale}/day_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Semaine':
		// 				// 		case 'Week':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/meal_items/${locale}/week_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Mois':
		// 				// 		case 'Month':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/meal_items/${locale}/month_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Année':
		// 				// 		case 'Year':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/meal_items/${locale}/year_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Cumul':
		// 				// 		case 'Cumul':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/meal_items/${locale}/cumul_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		default:
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/meal_items/${locale}/day_all?date=${dateTag.value}`, '_self');
		// 				// 	}
		// 				// }
		// 				// else if (compteur === "2") {
		// 				// 	switch (format) {
		// 				// 		case 'Jour':
		// 				// 		case 'Day':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/${locale}/restaurants/${resto.text}/employees/day_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Semaine':
		// 				// 		case 'Week':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/${locale}/restaurants/${resto.text}/employees/week_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Mois':
		// 				// 		case 'Month':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/${locale}/restaurants/${resto.text}/employees/month_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Année':
		// 				// 		case 'Year':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/${locale}/restaurants/${resto.text}/employees/year_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Cumul':
		// 				// 		case 'Cumul':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/${locale}/restaurants/${resto.text}/employees/cumul_all?date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		default:
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/${locale}/restaurants/${resto.text}/employees/day_all?date=${dateTag.value}`, '_self');
		// 				// 	}
		// 				// }

		// 				// else if (compteur === "3") {
		// 				// 	switch (format) {
		// 				// 		case 'Jour':
		// 				// 		case 'Day':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${ids}/${locale}/day_variable_new_survey_v2?all_enquete=true&date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Semaine':
		// 				// 		case 'Week':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${ids}/${locale}/week_variable_new_survey_v2?all_enquete=true&date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Mois':
		// 				// 		case 'Month':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${ids}/${locale}/month_variable_new_survey_v2?all_enquete=true&date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Année':
		// 				// 		case 'Year':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${ids}/${locale}/year_variable_new_survey_v2?all_enquete=true&date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		case 'Cumul':
		// 				// 		case 'Cumul':
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${ids}/${locale}/cumul_variable_new_survey_v2?all_enquete=true&date=${dateTag.value}`, '_self');
		// 				// 			break;
		// 				// 		default:
		// 				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${ids}/${locale}/day_variable_new_survey_v2?all_enquete=true&date=${dateTag.value}`, '_self');
		// 				// 	}
		// 				// }

		// 				// add rests ...
		// 			// end OLD ONCLICK HANDLE

		// 			if (doughnut.dataset.url) {
		// 				triggerLoadingDots(loadingDotsContainer);
		// 				window.open(doughnut.dataset.url, '_self');
		// 			}
		// 		}
		// 	});
		// }

		/////////////////////////////////////////////////////
		// Graph Général Index Gérant Doughnut
		const doughnut_gerant = document.querySelector(".doughnut_general_gerant");
		if (doughnut_gerant) {
			const doughnut_index_gerant = new Chart(doughnut_gerant, {
				type: 'doughnut',
				data: {
          labels: ["Note", improvement],
					datasets: [{
						datalabels: {
							display: false,
						},
						data: JSON.parse(doughnut_gerant.dataset.points),

						backgroundColor: [
							'#5d78ff',
							'#e5e7f1',
						],

						hoverBackgroundColor: [
							'rgb(81,138,268)',
							'rgb(248,233,260)',
						],

						hoverBorderColor: [
							'rgb(81,138,268)',
							'rgb(248,233,260)',
						],
						borderWidth: 1
					}],

				},
				options: {
					cutoutPercentage: 80,
					legend: {
						display: false
					},
				}
			});
			let chart = doughnut_index_gerant
			chart.options.animation = false;
		}

		////////////////////////////////////////////
		//Graph Général Index_Gérant par restaurant

		//Graphe des clients qui recommandent le resto.text
		const recommendation = document.getElementById("recommendation-chart");

		if (recommendation) {
			recommendationChart = new Chart(recommendation, {
				type: 'doughnut',
				data: {
          labels: ['NPS', improvement],
					datasets: [{
						datalabels: {
							display: false
						},
            data: JSON.parse(recommendation.dataset.points).map(point => (point !== null && point !== undefined) ? Math.round(point) : point),
						backgroundColor: JSON.parse(recommendation.dataset.points_co),

						hoverBackgroundColor: JSON.parse(recommendation.dataset.points_coback),

						borderWidth: 1
					}]
				},
				options: {
					cutoutPercentage: 80,
					legend: {
						display: false
					},
				}
			});

			let chart = recommendationChart
			chart.options.animation = false;

			console.log(recommendationChart.data.labels);
			console.log(recommendationChart.data.labels[0]);
			console.log(typeof recommendationChart.data.labels[0]);

			if (recommendationChart.data.labels[0] == 'NaN undefined' || recommendationChart.data.labels[0] == 'S NaN/NaN' || recommendationChart.data.labels[0] == 'undefined NaN' || `${recommendationChart.data.labels[0]}` == 'NaN') {
				location.reload();
			}

			recommendation.onclick = function () {
				// const queryString = window.location.search;
				// const urlParams = new URLSearchParams(queryString);
				// console.log("queryString", queryString)
				// console.log("urlParams", urlParams)
				// const enquete = urlParams.get('enquete[]');
				// let enquete_params = ""
				// if (enquete) {
				// 	enquete_params = `&enquete[]=${enquete}`
				// }
				// triggerLoadingDots(loadingDotsContainer);
				// window.open(`/groups/${group}/nps_index_gerant?${enquete_params}`, '_self');


				// const resto = document.querySelector(".resto");
				// if (resto) {
				// 	const group = document.querySelector(".resto").dataset.group
				// 	const sg = document.querySelector(".resto").dataset.sg
				// 	switch (format) {
				// 		case 'Jour':
				// 		case 'Day':
				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day_nps?date=${dateTag.value}`, '_self');
				// 			break;
				// 		case 'Semaine':
				// 		case 'Week':
				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/week_nps?date=${dateTag.value}`, '_self');
				// 			break;
				// 		case 'Mois':
				// 		case 'Month':
				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/month_nps?date=${dateTag.value}`, '_self');
				// 			break;
				// 		case 'Année':
				// 		case 'Year':
				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/year_nps?date=${dateTag.value}`, '_self');
				// 			break;
				// 		case 'Cumul':
				// 		case 'Cumul':
				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/cumul_nps?date=${dateTag.value}`, '_self');
				// 			break;
				// 		default:
				// 			window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day_nps?date=${dateTag.value}`, '_self');
				// 	}
				// }
			}
		}
		if (recommendation) {
			recommendation.onmouseover = function (e) {
				document.body.style.cursor = "pointer";
			}

			recommendation.onmouseout = function (e) {
				document.body.style.cursor = "";
			}
		}

		//Graphe polar area note meal du jour
		const polar = document.getElementById("note_jour_chart");

		if (polar) {
			polar_chart = new Chart(polar, {
				type: 'polarArea',
				data: JSON.parse(note_jour_chart.dataset.points),
				labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			});

			let chart = polar_chart
			chart.options.animation = false;

			console.log(polar_chart.data.labels);
			console.log(polar_chart.data.labels[0]);
			console.log(typeof polar_chart.data.labels[0]);

			if (polar_chart.data.labels[0] == 'NaN undefined' || polar_chart.data.labels[0] == 'S NaN/NaN' || polar_chart.data.labels[0] == 'undefined NaN' || `${polar_chart.data.labels[0]}` == 'NaN') {
				location.reload();
			}
		}

		//Graphe repartition note meal du jour
		const noteSatisfactions = document.querySelectorAll(".note-satisfaction-chart");

		if (noteSatisfactions.length > 0) {
			noteSatisfactions.forEach((noteSatisfaction) => {

				let labelsXAxe = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
				if (noteSatisfaction.dataset.maxXAxe) {
					labelsXAxe = []
					for (let i = 1; i <= noteSatisfaction.dataset.maxXAxe; i++) {
						labelsXAxe.push(i);
					}
				}
				note_satisfaction_generale = new Chart(noteSatisfaction, {
					type: 'bar',
					data: {
						labels: labelsXAxe,
						datasets: [{
							label: "Nombre d'avis par note ", //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
							data: JSON.parse(noteSatisfaction.dataset.points),
							backgroundColor: [
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
							],
							hoverBackgroundColor: [
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
							],

							borderColor: [
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
								'#5d78ff',
							],

							hoverBorderColor: [
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
							],
							borderWidth: 1
						}]
					},

					options: {
						plugins: {
							datalabels: {
								anchor: 'end',
								align: function (context) {
									return context.dataset.data[context.dataIndex] < 1 ? 'top' : 'start';
								},
								color: 'white',
							},
						},
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								gridLines: {
									display: false
								}
							}],
							yAxes: [{
								ticks: {
									beginAtZero: true
								},
								gridLines: {
									drawBorder: false,
									display: false
								}

							}]
						}

					}
				});

				let chart = note_satisfaction_generale
				chart.options.animation = false;

				console.log(note_satisfaction_generale.data.labels);
				console.log(note_satisfaction_generale.data.labels[0]);
				console.log(typeof note_satisfaction_generale.data.labels[0]);

				if (note_satisfaction_generale.data.labels[0] == 'NaN undefined' || note_satisfaction_generale.data.labels[0] == 'S NaN/NaN' || note_satisfaction_generale.data.labels[0] == 'undefined NaN' || `${note_satisfaction_generale.data.labels[0]}` == 'NaN') {
					location.reload();
				}
			})

		}

		// Graph of the nationalities of the customers who has responded the surveys.
		const allNationalityCharts = document.querySelectorAll(".nationality-chart");

		if (allNationalityCharts.length > 0) {
			allNationalityCharts.forEach((nationalityChart) => {
				let dataNationality = JSON.parse(nationalityChart.dataset.points)

				// It allows us to fixe .to_json issue when we've received an array of strings that contains whitespaces.
				let countries = new Array()
				dataNationality.countries.forEach((element, index) => {
					countries.push(`${element.split('+').join(' ')}`)
				});

				const labels = dataNationality.countries
				let images = dataNationality.flags

				images = images.map(png => {
						const image = new Image();
						if (png == "") {
							image.src = "";
						} else {
							image.src = png;
						}

						return image;
				});

				let counter = new Array()
				if (nationalityChart.dataset.type == 'percentage') {
					dataNationality.counter.forEach((value) => {
						counter.push((value * 100 / dataNationality.total_counter).toFixed(0))
					})
				} else {
						counter = dataNationality.counter
				}

				const displayNationalityGraph = new Chart(nationalityChart, {
					type: 'horizontalBar',
					// Insert flags images on chart.
					plugins: [{
						afterDraw: chart => {
							var ctx = chart.chart.ctx;
							var xAxis = chart.scales['x-axis-0'];
							var yAxis = chart.scales['y-axis-0'];
							yAxis.ticks.forEach((value, index) => {
								var x = yAxis.getPixelForTick(index);
								ctx.drawImage(images[index], xAxis.left - 26, x - 8);
							});
						}
					}],
					data: {
						labels: countries,
						datasets: [{
							label: '',
							data: counter,
							backgroundColor: '#5d78ff',
							hoverBackgroundColor: 'rgb(81,138,268)',
							borderColor: '#5d78ff',
							hoverBorderColor: 'rgb(81,138,268)',
							borderWidth: 1
						}]
					},

					options: {
						indexAxis: 'y',
						plugins: {
							datalabels: {
								anchor: 'end',
								align: function (context) {
									return (nationalityChart.dataset.type == 'percentage' && context.dataset.data[context.dataIndex] >= 5 && context.dataset.data[context.dataIndex] <= 95) ? 'end' : 'start';
								},
								formatter: (value, ctx) => {
									if (nationalityChart.dataset.type == 'percentage') {
										return value + '%';
									} else {
										return value;
									}
								},
								color: function (context) {
									return (nationalityChart.dataset.type == 'percentage' && context.dataset.data[context.dataIndex] >= 5 && context.dataset.data[context.dataIndex] <= 95) ? 'black' : 'white';
								},
							},
						},
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								gridLines: {
									display: false,
								},
								ticks: {
									beginAtZero: true,
									precision: 0,
									max: setMaxTicks(),
									maxTicksLimit: 5,
									callback: function (value, ticks) {
										if (nationalityChart.dataset.type == 'percentage') {
											return (value / this.max * 100).toFixed(0) + '%'; // Convert value to percentage.
										} else {
											return value
										}
									},
								}
							}],
							yAxes: [{
								gridLines: {
									drawBorder: false,
									display: false
								},
								ticks: {
									fontSize: 14,
									callback: function (value, ticks) {
										if (value.length >= 20) {
											return value.substr(0, 20) + '...' + '      '
										} else {
											return value + '      '
										}
									}
								}
							}]
						},
						tooltips: {
							callbacks: {
								label: function(ttItem){
										if (nationalityChart.dataset.type == 'integer') {
											return `${nationalityChart.dataset.labelPercentage}: ${(ttItem.xLabel * 100 / dataNationality.total_counter).toFixed(0)}%`
										} else {
											return `${nationalityChart.dataset.labelInteger}: ${Math.round(ttItem.xLabel * dataNationality.total_counter / 100)}`
										}
									}
								}
							}
					}
				});

				let chart = displayNationalityGraph
				chart.options.animation = false;

				// It allows to set an axis in percentage.
				function setMaxTicks() {
					if (nationalityChart.dataset.type == 'percentage') {
						return 100
					} else {
						return undefined
					};
				}
			})
		}


		//Graphe repartition entrée plat dessert
		const noteSatisfactionmeal = document.getElementById("evolution-satisfaction-meal");

		if (noteSatisfactionmeal) {
			note_satisfaction_meal = new Chart(noteSatisfactionmeal, {
				type: 'bar',
				data: {
					labels: JSON.parse(noteSatisfactionmeal.dataset.points_em),
					datasets: [{
						label: 'CSAT ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
						data: JSON.parse(noteSatisfactionmeal.dataset.points),
						backgroundColor: '#5D79FF',
						hoverBackgroundColor: 'rgb(81,138,268)',
						borderColor: 'rgb(81,138,268)',
						borderWidth: 1
					}]
				},
				options: {
					legend: {
						display: false
					},
					scales: {
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks: {
								callback: function(value) {
									return (value.length < 20 ? value : value.substr(0, 20) + '...');
								},
							}
						}],
						yAxes: [{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 10
							},
							gridLines: {
								drawBorder: false,
								display: false
							}
						}]
					},

					plugins: {
						datalabels: {
							anchor: 'end',
							align: function (context) {
								return context.dataset.data[context.dataIndex] < 8 ? 'top' : 'start';
							},
							color: function (context) {
								return context.dataset.data[context.dataIndex] < 8 ? '#5d78ff' : 'white';
							},
              formatter: function (value, context) {
                if (value) {
                  if (noteSatisfactionmeal.dataset.points) {
                    let percentage = Math.round(value) + "%";
                    return percentage;
                  } else {
                    return value;
                  }
                }
                return '';
              },
						}
					},
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
                if (value !== 0) {
                  if (noteSatisfactionmeal.dataset.points) {
                    return datasetLabel + ': ' + Math.round(value) + '%';
                  } else {
                    return datasetLabel + ': ' + value
                  }
                }
              },
            },
          },
				}
			});

			let chart = note_satisfaction_meal
			chart.options.animation = false;

			console.log(note_satisfaction_meal.data.labels);
			console.log(typeof note_satisfaction_meal.data.labels[0]);

			if (note_satisfaction_meal.data.labels[0] == 'NaN undefined' || note_satisfaction_meal.data.labels[0] == 'S NaN/NaN' || note_satisfaction_meal.data.labels[0] == 'undefined NaN' || `${note_satisfaction_meal.data.labels[0]}` == 'NaN') {
				location.reload();
			}

			noteSatisfactionmeal.onmouseover = function (e) {
				document.body.style.cursor = "pointer";

			}

			noteSatisfactionmeal.onmouseout = function () {
				document.body.style.cursor = "";
			}

			noteSatisfactionmeal.onclick = function (e) {
				let section = note_satisfaction_meal.getElementAtEvent(e);
				if (!section.length) return; // return if not clicked on section
				let label = section[0]._model.label;
				// add case for each label/section
				const ids = noteSatisfactionmeal.dataset.points_id.split("[").pop().split("]").shift().split(",");
				const titre = document.querySelector(".title").innerText;
				console.log(titre)
				console.log(noteSatisfactionmeal)

				let i = 0;
				note_satisfaction_meal.data.labels.forEach(function (element) {
					if (label === element) {
						if (titre === "SATISFACTION GÉNÉRALE PAR ÉTABLISSEMENT" || titre === "SATISFACTION GÉNÉRALE DE L'ÉTABLISSEMENT") {
							const group = document.querySelector(".resto").dataset.group
							const sg = document.querySelector(".resto").dataset.sg
							switch (format) {
								case 'Jour':
								case 'Day':

									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${ids[i]}/${locale}/day?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Semaine':
								case 'Week':

									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${ids[i]}/${locale}/week?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Mois':
								case 'Month':

									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${ids[i]}/${locale}/month?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Année':
								case 'Year':

									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${ids[i]}/${locale}/year?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Cumul':
								case 'Cumul':

									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${ids[i]}/${locale}/cumul?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								default:

									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${ids[i]}/${locale}/day?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
							}
						}

						else if (titre === "Satisfaction générale par établissement index gerant") {
							const groups = noteSatisfactionmeal.dataset.points_group.split("[").pop().split("]").shift().split(",");
							const sgs = noteSatisfactionmeal.dataset.points_sg.split("[").pop().split("]").shift().split(",");
							switch (format) {
								case 'Jour':
								case 'Day':

									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/day?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Semaine':
								case 'Week':

									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/week?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Mois':
								case 'Month':

									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/month?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Année':
								case 'Year':

									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/year?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Cumul':
								case 'Cumul':

									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/cumul?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								default:

									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/day?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
							}


						}

						else if (document.querySelector(".title.titre.detail-avis-carte.from-meal-all-for-js")) {
							const categories = JSON.parse(noteSatisfactionmeal.dataset.points_id);
							const group = document.querySelector(".title.titre.detail-avis-carte").dataset.group
							const queryString = window.location.search;
							const urlParams = new URLSearchParams(queryString);
							const master_categ = urlParams.get('mc')
							let mc = ''
							if (master_categ) {
								mc = `&mc=${master_categ}`
							}
							const enquete = urlParams.get('enquete[]');
							let enquete_params = ""
							if (enquete) {
								enquete_params = `&enquete[]=${enquete}`
							}

							triggerLoadingDots(loadingDotsContainer);
							window.open(`/${locale}/groups/${group}/category_index_gerant?category=${categories[i]}${mc}${enquete_params}`, '_self');
						}

						else if (document.querySelector(".title.titre.detail-avis-carte.from-multisite-mastcat-for-js")) {
							const group = document.querySelector(".title.titre.detail-avis-carte").dataset.group
							const queryString = window.location.search;
							const urlParams = new URLSearchParams(queryString);
							const master_categ = urlParams.get('mc');
							const enquete = urlParams.get('enquete[]');
							let enquete_params = ""
							if (enquete) {
								enquete_params = `&enquete[]=${encodeURIComponent(enquete)}`
							}
							let mc = ''
							if (noteSatisfactionmeal.dataset.points_id) {
								mc = `&mc=${JSON.parse(noteSatisfactionmeal.dataset.points_id)[i]}`
							}


							triggerLoadingDots(loadingDotsContainer);
							window.open(`/${locale}/groups/${group}/multisite_c?${mc}${enquete_params}`, '_self');
						}

						else if (document.querySelector(".satis-generale-index-gerant-for-js")) {
							const categories = noteSatisfactionmeal.dataset.points_em.split("[").pop().split("]").shift().split(",");
							const group = document.querySelector(".satis-generale-index-gerant-for-js").dataset.group
							const sgs = noteSatisfactionmeal.dataset.points_sg.split("[").pop().split("]").shift().split(",");

							switch (format) {
								case 'Jour':
								case 'Day':
									window.open(`/groups/${group}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/day?date=${new Date(date)}`, '_self');
									break;
								case 'Semaine':
								case 'Week':
									window.open(`/groups/${group}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/week?date=${new Date(date)}`, '_self');
									break;
								case 'Mois':
								case 'Month':
									window.open(`/groups/${group}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/month?date=${new Date(date)}`, '_self');
									break;
								case 'Année':
								case 'Year':
									window.open(`/groups/${group}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/year?date=${new Date(date)}`, '_self');
									break;
								case 'Cumul':
								case 'Cumul':
									window.open(`/groups/${group}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/cumul?date=${new Date(date)}`, '_self');
									break;
								default:
									window.open(`/groups/${group}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/day?date=${new Date(date)}`, '_self');
							}
						}

						else if (document.querySelector(".detail-avis-from-mastcat-for-js")) {
							const categories = noteSatisfactionmeal.dataset.points_em.split("[").pop().split("]").shift().split(",");
							const mastcats = noteSatisfactionmeal.dataset.points_id.split("[").pop().split("]").shift().split(",");
							const group = window.location.href.match(/groups\/(\d+)/)[1]
							const sg = window.location.href.match(/sous_groupes\/(\d+)/)[1]
							const resto = window.location.href.match(/restaurants\/(\d+)/)[1]
							switch (format) {
								case 'Jour':
								case 'Day':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto}/meal_items/${locale}/day_all?date=${new Date(date.setDate(date.getDate() - i))}&mc=${mastcats[i]}`, '_self');
									break;
								case 'Semaine':
								case 'Week':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto}/meal_items/${locale}/week_all?date=${new Date(date.setDate(date.getDate() - i))}&mc=${mastcats[i]}`, '_self');
									break;
								case 'Mois':
								case 'Month':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto}/meal_items/${locale}/month_all?date=${new Date(date.setDate(date.getDate() - i))}&mc=${mastcats[i]}`, '_self');
									break;
								case 'Année':
								case 'Year':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto}/meal_items/${locale}/year_all?date=${new Date(date.setDate(date.getDate() - i))}&mc=${mastcats[i]}`, '_self');
									break;
								case 'Cumul':
								case 'Cumul':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto}/meal_items/${locale}/cumul_all?date=${new Date(date.setDate(date.getDate() - i))}&mc=${mastcats[i]}`, '_self');
									break;
								default:
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto}/meal_items/${locale}/day_all?date=${new Date(date.setDate(date.getDate() - i))}&mc=${mastcats[i]}`, '_self');
							}
						}


						else {
							const group = document.querySelector(".resto").dataset.group
							const sg = document.querySelector(".resto").dataset.sg
							const queryString = window.location.search;
							const urlParams = new URLSearchParams(queryString);
							const master_categ = urlParams.get('mc')
							let mc = ''
							if (master_categ) {
								mc = `&mc=${master_categ}`
							}

							switch (format) {
								case 'Jour':
								case 'Day':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/categories/${ids[i]}/${locale}/day?date=${dateTag.value}${mc}`, '_self');
									break;
								case 'Semaine':
								case 'Week':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/categories/${ids[i]}/${locale}/week?date=${dateTag.value}${mc}`, '_self');
									break;
								case 'Mois':
								case 'Month':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/categories/${ids[i]}/${locale}/month?date=${dateTag.value}${mc}`, '_self');
									break;
								case 'Année':
								case 'Year':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/categories/${ids[i]}/${locale}/year?date=${dateTag.value}${mc}`, '_self');
									break;
								case 'Cumul':
								case 'Cumul':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/categories/${ids[i]}/${locale}/cumul?date=${dateTag.value}${mc}`, '_self');
									break;
								default:
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/categories/${ids[i]}/${locale}/day?date=${dateTag.value}${mc}`, '_self');
							}
						}
					}


					i = i + 1;
				})
				// add rests ...

			}
		}


		// EVOLUTION DE LA CLIENTÈLE DE MON RESTAURANT
		const courbeFidelity = document.getElementById("courbe-fidelity");

		if (courbeFidelity) {
			const courbe_satisfaction_servv = new Chart(courbeFidelity, {
				type: 'line',
				data: {
					labels: createLabelsArray(new Date(date.setDate(date.getDate()))),

					datasets: [{
						datalabels: {
							display: false,
						},
						label: 'Gold ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
						data: JSON.parse(courbeFidelity.dataset.points_i),
						fill: false,
						borderColor: '#c49c48',
						backgroundColor: '#c49c48',
						color: '#232947',
						borderWidth: 2
					},
					{
						datalabels: {
							display: false,
						},
						label: 'Silver ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
						data: JSON.parse(courbeFidelity.dataset.points_e),
						fill: false,
						borderColor: '#A5A49B',
						backgroundColor: '#A5A49B',
						color: '#232947',
						borderWidth: 2

					},
					{
						datalabels: {
							display: false,
						},
						label: 'Bronze ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
						data: JSON.parse(courbeFidelity.dataset.points_j),
						fill: false,
						borderColor: '#614E1A',
						backgroundColor: '#614E1A',
						color: 'white',
						borderWidth: 2
					},
					{
						datalabels: {
							display: false,
						},
						label: 'New ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
						data: JSON.parse(courbeFidelity.dataset.points_em),
						fill: false,
						borderColor: "#f4f3f3",
						backgroundColor: "#f4f3f3",
						color: '#232947',
						borderWidth: 2

					},
					]
				},
				options: {
					scales: {
						xAxes: [{
							gridLines: {
								display: false
							}
						}],
						yAxes: [{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 10
							},
							gridLines: {
								drawBorder: false,
								display: false
							}
						}]
					},
				}
			});

			let chart = courbe_satisfaction_servv
			chart.options.animation = false;

			console.log(courbe_satisfaction_servv.data.labels);
			console.log(courbe_satisfaction_servv.data.labels[0]);
			console.log(typeof courbe_satisfaction_servv.data.labels[0]);

			if (courbe_satisfaction_servv.data.labels[0] == 'NaN undefined' || courbe_satisfaction_servv.data.labels[0] == 'S NaN/NaN' || courbe_satisfaction_servv.data.labels[0] == 'undefined NaN' || `${courbe_satisfaction_servv.data.labels[0]}` == 'NaN') {
				location.reload();
			}
		}

		const notesGerantMealService = document.querySelectorAll(".evolution-gerant-meal-service");
		Array.prototype.forEach.call(notesGerantMealService, function (noteGerantMealService) {
			if (noteGerantMealService) {
				const note_gerant_meal_service = new Chart(noteGerantMealService, {
					type: 'bar',
					data: {
						labels: JSON.parse(noteGerantMealService.dataset.points_em),
						datasets: [{
							label: 'Moyenne avis ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
							data: JSON.parse(noteGerantMealService.dataset.points),
							backgroundColor: [
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
								'#5D79FF',
							],
							hoverBackgroundColor: [
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
							],
							borderColor: [
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
								'rgb(81,138,268)',
							],
							borderWidth: 1
						}]
					},
					options: {
						plugins: {
							datalabels: {
								anchor: 'end',
								align: function (context) {
									return context.dataset.data[context.dataIndex] < 8 ? 'top' : 'start';
								},
								color: function (context) {
									return context.dataset.data[context.dataIndex] < 8 ? '#5d78ff' : 'white';
								},
							}
						},
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								gridLines: {
									display: false
								},
								ticks: {
									callback: function(value) {
										return (value.length < 20 ? value : value.substr(0, 20) + '...');
									},
								}
							}],
							yAxes: [{
								ticks: {
									suggestedMin: 0,
									suggestedMax: 10
								},
								gridLines: {
									drawBorder: false,
									display: false
								}
							}]
						}
					}
				});

				let chart = note_gerant_meal_service
				chart.options.animation = false;

				noteGerantMealService.onmouseover = function (e) {
					document.body.style.cursor = "pointer";

				}

				noteGerantMealService.onmouseout = function () {
					document.body.style.cursor = "";
				}


				noteGerantMealService.onclick = function (e) {
					let section = note_gerant_meal_service.getElementAtEvent(e);
					if (!section.length) return; // return if not clicked on section
					let label = section[0]._model.label;
					// add case for each label/section
					const ids = noteGerantMealService.dataset.points_id.split("[").pop().split("]").shift().split(",");
					const titre = document.querySelector(".title-gerant").innerText;
					const groups = noteGerantMealService.dataset.points_group.split("[").pop().split("]").shift().split(",");
					const sgs = noteGerantMealService.dataset.points_sg.split("[").pop().split("]").shift().split(",");
					const gq_ids = noteGerantMealService.dataset.points_gq_ids.split("[").pop().split("]").shift().split(",");
					const gq_name = noteGerantMealService.dataset.gq.replace(/"/, '').replace(/"/, '')
					let i = 0;
					let url = ""
					let date_suffixe = "all"

					note_gerant_meal_service.data.labels.forEach(function (element) {
						if (gq_name === "Meal") {
							url = "meal_items"
						} else if (gq_name === "Service") {
							url = "employees"
						} else {
							if (gq_ids[i]) {
								url = `groupe_questions/${gq_ids[i]}`
								date_suffixe = "variable_new_survey_v2"
							}
						}
						if (label === element) {
							switch (format) {
								case 'Jour':
								case 'Day':
									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${url}/day_${date_suffixe}?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Semaine':
								case 'Week':
									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${url}/week_${date_suffixe}?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Mois':
								case 'Month':
									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${url}/month_${date_suffixe}?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Année':
								case 'Year':
									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${url}/year_${date_suffixe}?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								case 'Cumul':
								case 'Cumul':
									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${url}/cumul_${date_suffixe}?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
									break;
								default:
									window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${url}/day_${date_suffixe}?date=${new Date(date)}`, '_self');
									date = new Date(convertDate(dateTag.value));
							}
						}


						i = i + 1;
					})
					// add rests ...

				}
			}

		});


		// NPS gérant - dashboard comparatif des établissements   ET    rapport multienquete comparaison du NPS moyen par enquête   ET  comparaison NPS moyen par enquête multisite
		const notesGerantNps = document.querySelectorAll(".evolution-gerant-nps");
		Array.prototype.forEach.call(notesGerantNps, function (noteGerantNps) {
			if (noteGerantNps) {
				const note_gerant_meal_service = new Chart(noteGerantNps, {
					type: 'bar',
					data: {
						labels: JSON.parse(noteGerantNps.dataset.points_em),
						datasets: [{
							label: 'Moyenne avis ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
							data: JSON.parse(noteGerantNps.dataset.points),
							backgroundColor: function (context) {
								return context.dataset.data[context.dataIndex] < 0 ? '#fd397a' : '#1dc9b7';
							},
							borderWidth: 1
						}]
					},
					options: {
						plugins: {
							datalabels: {
								anchor: function (context) {
									return context.dataset.data[context.dataIndex] < 0 ? 'start' : 'end';
								},
								align: function (context) {
									return context.dataset.data[context.dataIndex] < 20 ? 'top' : 'start';
								},
								color: function (context) {
									if (context.dataset.data[context.dataIndex] < -20) {
										return 'white';
									} else if (context.dataset.data[context.dataIndex] > 20) {
										return 'white';
									} else {
										return '#5d78ff';
									}
								},
								display: function(context) {
									if (document.querySelector('.js-nb-avis')) {
										const nbSurveys = document.querySelectorAll('.js-nb-avis');
										const index = context.dataIndex;
                    if (nbSurveys[index] && nbSurveys[index].dataset) {
                      return parseInt(nbSurveys[index].dataset.nbAvis) > 0 ? true : false;
                    }
									}
								}
							}
						},
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								gridLines: {
									display: false
								},
								ticks: {
									callback: function(value) {
										return (value.length < 25 ? value : value.substr(0, 25) + '...');
									},
								}
							}],
							yAxes: [{
								ticks: {
									suggestedMin: -100,
									suggestedMax: 100
								},
								gridLines: {
									drawBorder: false,
									display: false
								}
							}]
						},
					}
				});

				let chart = note_gerant_meal_service
				chart.options.animation = false;


				noteGerantNps.onmouseover = function (e) {
					document.body.style.cursor = "pointer";

				}

				noteGerantNps.onmouseout = function () {
					document.body.style.cursor = "";
				}

				noteGerantNps.onclick = function (e) {
					let section = note_gerant_meal_service.getElementAtEvent(e);
					if (!section.length) return; // return if not clicked on section
					let label = section[0]._model.label;
					// add case for each label/section
					const groups = noteGerantNps.dataset.points_group.split("[").pop().split("]").shift().split(",");

					let i = 0;
					note_gerant_meal_service.data.labels.forEach(function (element) {
						if (label === element) {
							if (document.getElementById("comparatif-enquete-for-js")) {
								const ids = noteGerantNps.dataset.id_resto
								const enquete_ids = noteGerantNps.dataset.enqueteIds.split("[").pop().split("]").shift().split(",");
								const sgs = noteGerantNps.dataset.points_sg

								console.log(enquete_ids)
								switch (format) {
									case 'Jour':
									case 'Day':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs}/restaurants/${ids}/${locale}/day_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Semaine':
									case 'Week':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs}/restaurants/${ids}/${locale}/week_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Mois':
									case 'Month':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs}/restaurants/${ids}/${locale}/month_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Année':
									case 'Year':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs}/restaurants/${ids}/${locale}/year_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Cumul':
									case 'Cumul':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs}/restaurants/${ids}/${locale}/cumul_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									default:
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs}/restaurants/${ids}/${locale}/day_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
								}
							} else {
								const ids = noteGerantNps.dataset.points_id.split("[").pop().split("]").shift().split(",");
								const titre = document.querySelector(".title-gerant").innerText;
								const sgs = noteGerantNps.dataset.points_sg.split("[").pop().split("]").shift().split(",");
								switch (format) {
									case 'Jour':
									case 'Day':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/day_nps?date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Semaine':
									case 'Week':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/week_nps?date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Mois':
									case 'Month':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/month_nps?date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Année':
									case 'Year':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/year_nps?date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Cumul':
									case 'Cumul':
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/cumul_nps?date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									default:
										window.open(`/groups/${groups[i]}/sous_groupes/${sgs[i]}/restaurants/${ids[i]}/${locale}/day_nps?date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
								}
							}
						}
						i = i + 1;
					})
					// add rests ...
				}
			}

		});

		// Rapport multienquete - graph répartition du NPS par enquête
		const npsRepartPromoterEnquete = document.querySelector("#chart-decomposition-nps-per-enquete");
		if (npsRepartPromoterEnquete) {
			function initNpsRepartPromoterEnquete() {
				console.log("npsRepartPromoterEnquete")
				nps_decompo_per_enquete = new Chart(npsRepartPromoterEnquete, {
					type: 'bar',
					data: {
						labels: JSON.parse(npsRepartPromoterEnquete.dataset.points_em),
						datasets: [{
							label: 'Détracteurs',
							data: JSON.parse(npsRepartPromoterEnquete.dataset.points_detracteur),
							backgroundColor: '#fd397a'
						}, {
							label: 'Neutres',
							data: JSON.parse(npsRepartPromoterEnquete.dataset.points_neutre),
							backgroundColor: '#ffb822'
						}, {
							label: 'Promoteurs',
							data: JSON.parse(npsRepartPromoterEnquete.dataset.points_promoteur),
							backgroundColor: '#1dc9b7'
						}
						]
					},

					options: {
						tooltips: {
							displayColors: true,
							callbacks: {
								mode: 'x',
							},
						},
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                  const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
                  if (value !== 0) {
                    return datasetLabel + ': ' + Math.round(value) + '%';
                  }
                },
              },
            },
						plugins: {
							datalabels: {
								formatter: (value, ctx) => {
									let percentage = (value).toFixed(0) + "%";
									return percentage;
								},
								color: '#fff',
								display: function(context) {
									return context.dataset.data[context.dataIndex] !== 0;
								}
							}
						},
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								stacked: true,
								ticks: {
									callback: function(value) {
										return (value.length < 25 ? value : value.substr(0, 25) + '...');
									},
								}
							}],
							yAxes: [{
								ticks: {
									suggestedMin: 0,
									max: 100
								},
								stacked: true,
								gridLines: {
									display: false,
								}
							}]
						}
					}
				});

				let chart = nps_decompo_per_enquete
				chart.options.animation = false;


				npsRepartPromoterEnquete.onmouseover = function (e) {
					document.body.style.cursor = "pointer";

				}

				npsRepartPromoterEnquete.onmouseout = function () {
					document.body.style.cursor = "";
				}

				npsRepartPromoterEnquete.onclick = function (e) {
					let section = nps_decompo_per_enquete.getElementAtEvent(e);
					if (!section.length) return; // return if not clicked on section
					let label = section[0]._model.label;
					let i = 0;
					nps_decompo_per_enquete.data.labels.forEach(function (element) {
						if (label === element) {
							if (document.getElementById("repart-nps-for-js")) {
								const enquete_ids = npsRepartPromoterEnquete.dataset.enqueteIds.split("[").pop().split("]").shift().split(",");
								const group = document.querySelector(".resto").dataset.group
								const sg = document.querySelector(".resto").dataset.sg
								console.log(enquete_ids)
								switch (format) {
									case 'Jour':
									case 'Day':
										window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Semaine':
									case 'Week':
										window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/week_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Mois':
									case 'Month':
										window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/month_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Année':
									case 'Year':
										window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/year_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									case 'Cumul':
									case 'Cumul':
										window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/cumul_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
										break;
									default:
										window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day_nps?enquete=${enquete_ids[i]}&date=${new Date(date)}`, '_self');
										date = new Date(convertDate(dateTag.value));
								}
							}
						}
						i = i + 1;
					})
				}
			}

			initNpsRepartPromoterEnquete();
		}


		// DISTRIBUTION HORAIRE
		const npsHoraireGq = document.querySelectorAll("#nps-horaire-gq");
		Array.prototype.forEach.call(npsHoraireGq, function (npsHourly) {
			if (npsHourly) {
				console.log("npsHourly")
				const nps_decompo_per_enquete = new Chart(npsHourly, {
					type: 'bar',
					data: {
						labels: JSON.parse(npsHourly.dataset.points_em),
						datasets: [ {
							label: JSON.parse(npsHourly.dataset.labels_repartition)[0],
							data: JSON.parse(npsHourly.dataset.points_tres_insatisfait),
							backgroundColor: '#FA0000'
						}, {
							label: JSON.parse(npsHourly.dataset.labels_repartition)[1],
							data: JSON.parse(npsHourly.dataset.points_insatisfait),
							backgroundColor: '#fd397a'
						}, {

							label: JSON.parse(npsHourly.dataset.labels_repartition)[2],
							data: JSON.parse(npsHourly.dataset.points_neutre),
							backgroundColor: '#ffb822'
						}, {
							label: JSON.parse(npsHourly.dataset.labels_repartition)[3],
							data: JSON.parse(npsHourly.dataset.points_satisfait),
							backgroundColor: '#1dd18f'
						}, {
							label: JSON.parse(npsHourly.dataset.labels_repartition)[4],
							data: JSON.parse(npsHourly.dataset.points_tres_satisfait),
							backgroundColor: '#1dc9b7'
						}
						]
					},

					options: {
						maintainAspectRatio: false,
						tooltips: {
							displayColors: true,
							callbacks: {
								mode: 'x',
							},
						},
						plugins: {
							datalabels: {
								formatter: (value, ctx) => {
									let percentage = (value).toFixed(0) + "%";
									return percentage;
								},
								color: '#fff',
								display: function(context) {
									return context.dataset.data[context.dataIndex] !== 0;
								}
							}
						},
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								ticks: {
									display: true
								},
								stacked: true,
								offset: true
							},
							{
								position: 'top',
								ticks: {
									display: true
								},
								stacked: true,
								offset: true,
								gridLines: {
									display: false,
								},
								labels: JSON.parse(npsHourly.dataset.nb_avis)
							}],
							yAxes: [{
								ticks: {
									suggestedMin: 0,
									max: 100
								},

								stacked: true,
								gridLines: {
									display: false,
								}
							}]
						}
					}
				});
				nps_decompo_per_enquete.update();

				let chart = nps_decompo_per_enquete
				chart.options.animation = false;

			}
		});

		const npsHoraireGqEmojis = document.querySelectorAll("#nps-horaire-gq-emojis");
		Array.prototype.forEach.call(npsHoraireGqEmojis, function (npsHourlyEmojis) {
			if (npsHourlyEmojis) {
				console.log("npsHourlyEmojis")
				const nps_decompo_per_enquete = new Chart(npsHourlyEmojis, {
					type: 'bar',
					data: {
						labels: JSON.parse(npsHourlyEmojis.dataset.points_em),

						datasets: [ {
							label: JSON.parse(npsHourlyEmojis.dataset.labels_repartition)[0],
							data: JSON.parse(npsHourlyEmojis.dataset.points_detracteur_very_sad),
							backgroundColor: '#FA0000'
						}, {
							label: JSON.parse(npsHourlyEmojis.dataset.labels_repartition)[1],
							data: JSON.parse(npsHourlyEmojis.dataset.points_detracteur_sad),
							backgroundColor: '#fd397a'
						}, {

							label: JSON.parse(npsHourlyEmojis.dataset.labels_repartition)[2],
							data: JSON.parse(npsHourlyEmojis.dataset.points_neutre),
							backgroundColor: '#ffb822'
						}, {
							label: JSON.parse(npsHourlyEmojis.dataset.labels_repartition)[3],
							data: JSON.parse(npsHourlyEmojis.dataset.points_promoteur_happy),
							backgroundColor: '#1dd18f'
						}, {
							label: JSON.parse(npsHourlyEmojis.dataset.labels_repartition)[4],
							data: JSON.parse(npsHourlyEmojis.dataset.points_promoteur_very_happy),
							backgroundColor: '#1dc9b7'
						}
						]
					},

					options: {
						maintainAspectRatio: false,
						tooltips: {
							displayColors: true,
							callbacks: {
								mode: 'x',
							},
						},
						plugins: {
							datalabels: {
								formatter: (value, ctx) => {
									let percentage = (value).toFixed(0) + "%";
									return percentage;
								},
								color: '#fff',
								display: function(context) {
									return context.dataset.data[context.dataIndex] !== 0;
								}
							}
						},
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								ticks: {
									display: true
								},
								stacked: true,
								offset: true
							},
							{
								position: 'top',
								ticks: {
									display: true
								},
								stacked: true,
								offset: true,
								gridLines: {
									display: false,
								},
								labels: JSON.parse(npsHourlyEmojis.dataset.nb_avis),
							}],
							yAxes: [{
								ticks: {
									suggestedMin: 0,
									max: 100
								},

								stacked: true,
								gridLines: {
									display: false,
								}
							}]
						}
					}
				});
				nps_decompo_per_enquete.update();

				let chart = nps_decompo_per_enquete
				chart.options.animation = false;

			}
		});

    chartComparisonCsat();
		// const csatComparisonRestaurantsGq = document.querySelectorAll("#csat-comparison-restaurants-gq");
		// Array.prototype.forEach.call(csatComparisonRestaurantsGq, function (csatComparison) {
		// 	if (csatComparison) {
		// 		console.log("csatComparison")
		// 		const csat_per_restaurant_gq = new Chart(csatComparison, {
		// 			type: 'horizontalBar',
		// 			data: {
		// 				labels: JSON.parse(csatComparison.dataset.restaurants),
		// 				datasets: [{
		// 					label: JSON.parse(csatComparison.dataset.labels_repartition)[0],
		// 					data: JSON.parse(csatComparison.dataset.detractor),
		// 					backgroundColor: '#fd397a',
		// 					yAxisID: 'left-y-axis'
		// 				}, {
		// 					label: JSON.parse(csatComparison.dataset.labels_repartition)[1],
		// 					data: JSON.parse(csatComparison.dataset.neutral),
		// 					backgroundColor: '#ffb822',
		// 					yAxisID: 'left-y-axis'
		// 				}, {
		// 					label: JSON.parse(csatComparison.dataset.labels_repartition)[2],
		// 					data: JSON.parse(csatComparison.dataset.promotor),
		// 					backgroundColor: '#1dc9b7',
		// 					yAxisID: 'left-y-axis'
		// 				}
		// 				]
		// 			},

		// 			options: {
		// 				indexAxis: 'y',
		// 				elements: {
		// 					bar: {
		// 						borderWidth: 2,
		// 					}
		// 				},
		// 				maintainAspectRatio: false,
		// 				plugins: {
		// 					datalabels: {
		// 						formatter: function(value, ctx) {
    //               let percentage = Math.round(value) + "%";
		// 							return percentage;
		// 						},
		// 						color: '#fff',
		// 						display: function(context) {
		// 							return context.dataset.data[context.dataIndex] !== 0;
		// 						}
		// 					}
		// 				},
    //         tooltips: {
    //           callbacks: {
    //             label: function (tooltipItem, data) {
    //               const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
    //               const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
    //               if (value !== 0) {
    //                 return datasetLabel + ': ' + value + '%';
    //               }
    //             },
    //           },
    //         },
		// 				legend: {
		// 					display: false
		// 				},
		// 				scales: {
		// 					xAxes: [{
		// 						stacked: true,
		// 						offset: true,
		// 						ticks: {
		// 							min: 0,
		// 							max: 100,
		// 						},
		// 						display: false, // Hide x-axis scale
		// 						gridLines: {
		// 							display: false
		// 						},
		// 					}],
		// 					yAxes: [{
		// 						id: 'left-y-axis',
		// 						ticks: {
		// 							display: true,
		// 						},
		// 						stacked: true,
		// 						offset: true,
		// 						gridLines: {
		// 							display: false,
		// 						}
		// 						}]
		// 				},
    //         tooltips: {
    //           callbacks: {
    //             label: function (tooltipItem, data) {
    //               const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
    //               const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
    //               if (value !== 0) {
    //                 return datasetLabel + ': ' + value + '%';
    //               }
    //             },
    //           },
    //         },

		// 			}
		// 		});
		// 		csat_per_restaurant_gq.update();

		// 		let chart = csat_per_restaurant_gq
		// 		chart.options.animation = false;


		// 	}
		// });


    chartRankingDistrib();
		// const rankColors = {
		// 	1: '#6C6EA0',
		// 	2: '#66C7F4',
		// 	3: 'E7CFBC',
		// 	4: '#86BBBD',
		// 	5: '#544E61',
		// 	6: '#85BAA1',
		// 	7: '#76949F',
		// 	8: '#CEEDDB',
		// 	9: '#F9B5AC',
		// 	10: '#F9B5AC',
		// 	11: '#D7CDCC',
		// 	12: '#59656F',
		// 	"not_chosen": '#ddd'
		// };

		// const classementDistributionGq = document.querySelectorAll(".classement-distribution-gq");

		// Array.prototype.forEach.call(classementDistributionGq, function (classementDistribution) {
		// 	if (classementDistribution) {
		// 		const searchBy = escapeSelector(classementDistribution.dataset.searchBy);
		// 		const data = JSON.parse(JSON.parse(document.querySelector(`.translated-tags-${searchBy}-distribution`).textContent.trim()));
		// 		console.log("distribution data:", data);
		// 		const labels = Object.keys(data).map(tag => {
		// 			if (tag.length > 30) {
		// 				return tag.substring(0, 30) + '...';
		// 			}
		// 			return tag;
		// 		});
		// 		const fullLabels = Object.keys(data); // For tooltips

		// 		const datasets = [];
		// 		Object.keys(rankColors).forEach(rank => {
		// 			const rankData = fullLabels.map(tag => data[tag]?.ranks[rank]?.percentage || 0);
		// 			const hasData = rankData.some(percentage => percentage > 0);

		// 			if (hasData) {
		// 				let label = "";
		// 				if (rank == "not_chosen") {
		// 					label = locale == 'fr' ? "Non choisi" : "Not chosen";
		// 				} else {
		// 					label = locale == 'fr' ? `Rang ${rank}` : `Rank ${rank}`;
		// 				}

		// 				console.log("rankData", rankData)
		// 				const dataset = {
		// 					label: label,
		// 					data: rankData,
		// 					backgroundColor: rankColors[rank],
		// 					stack: 'Stack 0'
		// 				};
		// 				datasets.push(dataset);
		// 			}
		// 		});

		// 		// Load images from external URLs
		// 		const images = {};
		// 		let hasPictures = false;
		// 		const promises = Object.keys(data).map(label => {
		// 			return new Promise((resolve, reject) => {
		// 				if (data[label].picture) {
		// 					hasPictures = true;
		// 					const img = new Image();
		// 					img.src = data[label].picture;
		// 					img.onload = () => {
		// 						images[label] = img;
		// 						resolve();
		// 					};
		// 					img.onerror = reject;
		// 				} else {
		// 					resolve(); // Resolve immediately if no picture
		// 				}
		// 			});
		// 		});

		// 		// Custom plugin to draw images inside the labels
		// 		// const imagePlugin = {
		// 		// 	afterDraw: function(chart) {
		// 		// 		if (!hasPictures) return; // Only draw images if they exist
		// 		// 		const ctx = chart.ctx;
		// 		// 		chart.data.labels.forEach((label, index) => {
		// 		// 			const img = images[fullLabels[index]];
		// 		// 			const y = chart.scales['y-axis-0'].getPixelForTick(index) - 10; // Adjust the y position
		// 		// 			const x = chart.scales['x-axis-0'].left - 30; // Adjust the x position
		// 		// 			if (img) {
		// 		// 				ctx.drawImage(img, x, y, 100, 100); // Adjust the size and position
		// 		// 				ctx.fillText(labels[index], x + 30, y + 12); // Adjust the text position
		// 		// 			} else {
		// 		// 				ctx.fillText(labels[index], x, y + 12); // Adjust the text position
		// 		// 			}
		// 		// 		});
		// 		// 	}
		// 		// };

		// 		// // Register the plugin
		// 		// if (hasPictures) {
		// 		// 	Chart.plugins.register(imagePlugin);
		// 		// }

		// 		// Wait for all images to load before creating the chart
		// 		Promise.all(promises).then(() => {
		// 			console.log("hasPictures", hasPictures)
		// 			const classement_distribution_question_gq = new Chart(classementDistribution, {
		// 				type: 'horizontalBar',
		// 				plugins: [{
		// 					afterDraw: chart => {
		// 						if (!hasPictures) return; // Only draw images if they exist
		// 						const ctx = chart.chart.ctx;
		// 						const xAxis = chart.scales['x-axis-0'];
		// 						const yAxis = chart.scales['left-y-axis'];
		// 						// const imageHeight = window.innerHeight * 0.06; // 6vh in pixels - same as the size of the images in the tag questions' dashboards
		// 						const barHeight = (chart.chartArea.bottom - chart.chartArea.top) / chart.data.labels.length; // Calculate bar height dynamically
    //         		const imageHeight = barHeight * 0.8; // Set image height to 80% of the bar height
		// 						const padding = 0; // Padding between images
		// 						chart.data.labels.forEach((label, index) => {
		// 							const img = images[fullLabels[index]];
		// 							if (img) {
		// 								const y = yAxis.getPixelForTick(index) - imageHeight / 2 + (padding * index); // centers the image vertically
		// 								const aspectRatio = img.width / img.height;
		// 								const imageWidth = imageHeight * aspectRatio;
		// 								const x = xAxis.left - imageWidth; // vertical position -> -: move left | +: move right
		// 								ctx.drawImage(img, x, y, imageWidth, imageHeight); // img draw here
		// 								ctx.textAlign = 'left';
		// 								ctx.textBaseline = 'middle';
		// 								// ctx.fillText(labels[index], x + imageWidth + 5, y + imageHeight / 2); // text position here
		// 							} else { // no image, just text
		// 								const y = yAxis.getPixelForTick(index);
		// 								const x = xAxis.left - 0; // text position here
		// 								ctx.textAlign = 'left';
		// 								ctx.textBaseline = 'middle';
		// 								ctx.fillText(labels[index], x, y);
		// 							}
		// 						});
		// 					}
		// 				}],
		// 				data: {
		// 					labels: hasPictures ? labels.map(_label => ("")) : labels,
		// 					datasets: datasets
		// 				},
		// 				options: {
		// 					layout: {
		// 						padding: {
		// 							left: hasPictures ? 130 : 0, // more space in the left of the charts for the pictures
		// 							right: 0,
		// 							top: 0,
		// 							bottom: 0
		// 						},
		// 					},
		// 					indexAxis: 'y',
		// 					elements: {
		// 						bar: {
		// 							borderWidth: 1,
		// 						}
		// 					},
		// 					maintainAspectRatio: false,
		// 					plugins: {
		// 						datalabels: {
		// 							formatter: function (value, ctx) {
		// 								let percentage = Math.round(value) + "%";
		// 								return percentage;
		// 							},
		// 							color: '#fff',
		// 							display: function (context) {
		// 								return context.dataset.data[context.dataIndex] > 5;
		// 							}
		// 						}
		// 					},
		// 					tooltips: {
		// 						callbacks: {
		// 							title: function (tooltipItems, data) {
		// 								const idx = tooltipItems[0].index;
		// 								const label = fullLabels[idx];
		// 								return splitLabel(label, 50); // Split label into multiple lines with a max length of 50 characters per line
		// 							},
		// 							label: function (tooltipItem, data) {
		// 								const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
		// 								const value = Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) || 0;
		// 								if (value !== 0) {
		// 									return datasetLabel + ': ' + value + '%';
		// 								}
		// 							},
		// 						},
		// 					},
		// 					legend: {
		// 						display: true,
		// 						position: 'bottom',
		// 					},
		// 					scales: {
		// 						xAxes: [{
		// 							gridLines: {
		// 								display: false,
		// 							},
		// 							stacked: true,
		// 							offset: true,
		// 							ticks: {
		// 								min: 0,
		// 								max: 100,
		// 							},
		// 							barThickness: 4,
		// 							barPercentage: 0.8,
		// 							categoryPercentage: 0.9, // Adjust as needed
		// 						}],
		// 						yAxes: [{
		// 							id: 'left-y-axis',
		// 							ticks: {
		// 								display: true,
		// 								callback: function (value, index, values) {
		// 									if (value.length > 30) {
		// 										return value.substring(0, 30) + '...';
		// 									}
		// 									return value;
		// 								}
		// 							},
		// 							stacked: true,
		// 							offset: true,
		// 							gridLines: {
		// 								display: false,
		// 							}
		// 						}]
		// 					},
		// 				}
		// 			});

		// 			classement_distribution_question_gq.update();
		// 			let chart = classement_distribution_question_gq;
		// 			chart.options.animation = false;
		// 		}).catch(error => {
		// 			console.error('Failed to load images:', error);
		// 		});
		// 	}
		// });



		// const classementScoreGq = document.querySelectorAll(".classement-score-gq");

		// Array.prototype.forEach.call(classementScoreGq, function (classementScore) {
		// 	if (classementScore) {
		// 		const searchBy = escapeSelector(classementScore.dataset.searchBy);
		// 		const data = JSON.parse(JSON.parse(document.querySelector(`.translated-tags-${searchBy}-score`).textContent.trim()));
		// 		console.log("score data: ", data);
		// 		const labels = Object.keys(data).map(tag => {
		// 			if (tag.length > 30) {
		// 				return tag.substring(0, 30) + '...';
		// 			}
		// 			return tag;
		// 		});
		// 		const fullLabels = Object.keys(data); // For tooltips

		// 		// Load images from external URLs
		// 		const images = {};
		// 		let hasPictures = false;
		// 		const promises = Object.keys(data).map(label => {
		// 			return new Promise((resolve, reject) => {
		// 				if (data[label].picture) {
		// 					hasPictures = true;
		// 					const img = new Image();
		// 					img.src = data[label].picture;
		// 					img.onload = () => {
		// 						images[label] = img;
		// 						resolve();
		// 					};
		// 					img.onerror = reject;
		// 				} else {
		// 					resolve(); // Resolve immediately if no picture
		// 				}
		// 			});
		// 		});
		// 		Promise.all(promises).then(() => {
		// 			const classement_score_question_gq = new Chart(classementScore, {
		// 				type: 'horizontalBar',
		// 				plugins: [{
		// 					afterDraw: chart => {
		// 						if (!hasPictures) return; // Only draw images if they exist
		// 						const ctx = chart.chart.ctx;
		// 						const xAxis = chart.scales['x-axis-0'];
		// 						const yAxis = chart.scales['y-axis-0'];
		// 						// const imageHeight = window.innerHeight * 0.06; // 6vh in pixels - same as the size of the images in the tag questions' dashboards
		// 						const barHeight = (chart.chartArea.bottom - chart.chartArea.top) / chart.data.labels.length; // Calculate bar height dynamically
    //         		const imageHeight = barHeight * 0.8; // Set image height to 80% of the bar height
		// 						const padding = 0; // Padding between images
		// 						chart.data.labels.forEach((label, index) => {
		// 							const img = images[fullLabels[index]];
		// 							if (img) {
		// 								const y = yAxis.getPixelForTick(index) - imageHeight / 2 + (padding * index); // centers the image vertically
		// 								const aspectRatio = img.width / img.height;
		// 								const imageWidth = imageHeight * aspectRatio;
		// 								const x = xAxis.left - imageWidth - 10; // vertical position -> +: move left | -: move right
		// 								ctx.drawImage(img, x, y, imageWidth, imageHeight); // img draw here
		// 								ctx.textAlign = 'left';
		// 								ctx.textBaseline = 'middle';
		// 								// ctx.fillText(labels[index], x + imageWidth + 5, y + imageHeight / 2); // text position here
		// 							} else { // no image, just text
		// 								const y = yAxis.getPixelForTick(index);
		// 								const x = xAxis.left - 40; // text position here
		// 								ctx.textAlign = 'left';
		// 								ctx.textBaseline = 'middle';
		// 								ctx.fillText(labels[index], x, y);
		// 							}
		// 						});
		// 					}
		// 				}],
		// 				data: {
		// 					labels: hasPictures ? labels.map(_label => ("")) : labels,
		// 					datasets: [{
		// 						data: fullLabels.map(tag => data[tag]?.percentage || 0),
		// 						backgroundColor: '#5d78ff',
		// 						hoverBackgroundColor: '#5d78ff',
		// 						borderWidth: 1,
		// 					}]
		// 				},
		// 				options: {
		// 					layout: {
		// 						padding: {
		// 							left: hasPictures ? 140 : 0, // more space in the left of the charts for the pictures
		// 							right: 0,
		// 							top: 0,
		// 							bottom: 0
		// 						}
		// 					},
		// 					legend: {
		// 						display: false,
		// 					},
		// 					plugins: {
		// 						datalabels: {
		// 							display: true, // Ensure data labels are displayed
		// 							anchor: 'end',
		// 							align: function (context) {
		// 								const max_value = context.dataset.data[0];
		// 								const percent_from_max = (context.dataset.data[context.dataIndex] * 100) / max_value ;

		// 								return percent_from_max < 80 ? 'end' : 'start';
		// 							},
		// 							color: function (context) {
		// 								const max_value = context.dataset.data[0];
		// 								const percent_from_max = (context.dataset.data[context.dataIndex] * 100) / max_value ;
		// 								return percent_from_max < 80 ? '#5d78ff' : 'white';
		// 							},
		// 							formatter: function (value, context) {
		// 								return `${context.dataIndex + 1}`; // Display the rank based on the index
		// 							},
		// 						}
		// 					},
		// 					tooltips: {
		// 						callbacks: {
		// 							title: function (tooltipItems, data) {
		// 								const idx = tooltipItems[0].index;
		// 								const label = fullLabels[idx];
		// 								return splitLabel(label, 50); // Split label into multiple lines with a max length of 50 characters per line
		// 							},
		// 							label: function (tooltipItem, data) {
		// 								const rank = tooltipItem.index + 1;
		// 								return locale == "fr" ? 'Rang ' + rank : 'Rank ' + rank; // Return the rank
		// 							},
		// 						},
		// 					},
		// 					scales: {
		// 						xAxes: [{
		// 							display: false, // Hide x-axis scale
		// 							gridLines: {
		// 								display: false
		// 							},
		// 						}],
		// 						yAxes: [{
		// 							gridLines: {
		// 								display: false
		// 							},
		// 							ticks: {
		// 								callback: function(value) {
		// 									return (value.length < 45 ? value : value.substr(0, 45) + '...');
		// 								},
		// 							}
		// 						}]
		// 					}
		// 				}
		// 			});
		// 			classement_score_question_gq.update();
		// 			let chart = classement_score_question_gq;
		// 			chart.options.animation = false;
		// 		});

		// 	}
		// });



		//Création de labels spécials pour les courbes
		const createLabelsCourbe = (date) => {

			if (format === "Mois") {
				return createLabelsArray(new Date(date.setMonth(date.getMonth())))
			}
			else if (format === "Année") {
				return createLabelsArray(new Date(date.setYear(date.getFullYear())))
			}
			else if (format === "Jour") {
				return createLabelsArray(new Date(date.setDate(date.getDate())))
			}
			else {
				return createLabelsArray(new Date(date.setDate(date.getDate())))
			}

		};

		const backgroundborderColor = [
			"#6C6EA0",
			"#66C7F4",
			"#C1CAD6",
			"#86BBBD",
			"#544E61",
			"#76949F",
			"#85BAA1",
			"#CEEDDB",
			"#F9B5AC",
			"#D7CDCC",
			"#59656F",
			"#F2B880",
			"#533747",
			"#D0D6B5",
			"#034078",
			"#7698B3",
			"#7F557D",
			"#1282A2",
			"#001F54",
			"#E7CFBC"
		];

		const createDatacategory = (courbeSatisfaction) => {
			let labelObjects = [];
			let dataset = courbeSatisfaction.dataset; // easier on the eyes
			for (let i = 0; i < (dataset.size); i++) {
				let labelObject = {
					datalabels: {
						display: false,
					},
					label: JSON.parse(dataset[`points_lab_${i}`]),
					data: JSON.parse(dataset[`points_${i}`]),
					fill: false,
					borderColor: backgroundborderColor[i],
					backgroundColor: backgroundborderColor[i],
					borderWidth: 2
				}
				labelObjects.push(labelObject);
			}
			return labelObjects;
		};

		//Graphe courbe satisfaction category
		const courbeSatisfaction = document.getElementById("courbe-satisfaction-chart");

		if (courbeSatisfaction) {
			courbe_satisfaction_generale = new Chart(courbeSatisfaction, {
				type: 'line',
				data: {
					labels: createLabelsCourbe(date),
					datasets: createDatacategory(courbeSatisfaction),
				},
				options: {
					scales: {
						xAxes: [{
							gridLines: {
								display: false
							}
						}],
						yAxes: [{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 10
							},
							gridLines: {
								drawBorder: false,
								display: false
							}
						}]
					}
				}
			});

			// allCharts.push(courbe_satisfaction_generale)
			let chart = courbe_satisfaction_generale
			chart.options.animation = false;

			console.log(courbe_satisfaction_generale.data.labels);
			console.log(courbe_satisfaction_generale.data.labels[0]);
			console.log(typeof courbe_satisfaction_generale.data.labels[0]);

			if (courbe_satisfaction_generale.data.labels[0] == 'NaN undefined' || courbe_satisfaction_generale.data.labels[0] == 'S NaN/NaN' || courbe_satisfaction_generale.data.labels[0] == 'undefined NaN' || `${courbe_satisfaction_generale.data.labels[0]}` == 'NaN') {
				location.reload();
			}
		}

		const borderbackgroundColor = [
			"#6C6EA0",
			"#66C7F4",
			"#C1CAD6",
			"#86BBBD",
			"#544E61",
			"#76949F",
			"#85BAA1",
			"#CEEDDB",
			"#F9B5AC",
			"#D7CDCC",
			"#59656F",
			"#F2B880",
			"#533747",
			"#D0D6B5",
			"#034078",
			"#7698B3",
			"#7F557D",
			"#1282A2",
			"#001F54",
			"#E7CFBC"
		];

		const createData = (courbeSatisfactionserv) => {
			let labelObjects = [];
			let dataset = courbeSatisfactionserv.dataset; // easier on the eyes
			for (let i = 0; i < (dataset.size); i++) {
				let labelObject = {
					datalabels: {
						display: false,
					},
					label: JSON.parse(dataset[`points_lab_${i}`]),
					data: JSON.parse(dataset[`points_${i}`]),
					fill: false,
					borderColor: borderbackgroundColor[i],
					backgroundColor: borderbackgroundColor[i],
					borderWidth: 2
				}
				labelObjects.push(labelObject);
			}
			return labelObjects;
		}

		//Graphe courbe satisfaction serveurs
		const courbeSatisfactionserv = document.getElementById("courbe-satisfaction-service-chart");

		if (courbeSatisfactionserv) {
			courbe_satisfaction_serv = new Chart(courbeSatisfactionserv, {
				type: 'line',
				data: {
					labels: createLabelsCourbe(date),
					datasets: createData(courbeSatisfactionserv)
				},
				options: {
					scales: {
						xAxes: [{
							gridLines: {
								display: false
							}
						}],
						yAxes: [{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 10
							},
							gridLines: {
								drawBorder: false,
								display: false
							}
						}]
					}
				}
			});

			let chart = courbe_satisfaction_serv
			chart.options.animation = false;

			console.log(courbe_satisfaction_serv.data.labels);
			console.log(courbe_satisfaction_serv.data.labels[0]);
			console.log(typeof courbe_satisfaction_serv.data.labels[0]);

			if (courbe_satisfaction_serv.data.labels[0] == 'NaN undefined' || courbe_satisfaction_serv.data.labels[0] == 'S NaN/NaN' || courbe_satisfaction_serv.data.labels[0] == 'undefined NaN' || `${courbe_satisfaction_serv.data.labels[0]}` == 'NaN') {
				location.reload();
			}
		}

		//Graphe comparaison des différents meal_items par categorie (entrée, plat, dessert)
		const comparaison_tag = document.getElementById("comparaison-meal-items");

		if (comparaison_tag) {
      console.log('graphe comparaison meal category')

      const timeframe = JSON.parse(comparaison_tag.dataset.timeframe)
      let horizontalBarChartData;

      if (timeframe === "cumul") {
        horizontalBarChartData = {
          labels: removeDots(JSON.parse(comparaison_tag.dataset.points)[2]),
          datasets: [{
            label: labelUn,
            backgroundColor: '#5d78ff',
            borderColor: '#5d78ff',
            borderWidth: 1,
            data: JSON.parse(comparaison_tag.dataset.points)[0],
          }]
        };

      } else {
        horizontalBarChartData = {
          labels: removeDots(JSON.parse(comparaison_tag.dataset.points)[2]),
          datasets: [{
            label: labelUn,
            backgroundColor: '#5d78ff',
            borderColor: '#5d78ff',
            borderWidth: 1,
            data: JSON.parse(comparaison_tag.dataset.points)[0],
          }, {
            label: labelDeux,
            backgroundColor: '#e5e7f1',
            borderColor: '#e5e7f1',
            data: JSON.parse(comparaison_tag.dataset.points)[1]
          }]
        };
      }

			comparaison_items = new Chart(comparaison_tag, {
				type: 'horizontalBar',
				data: horizontalBarChartData,
				labels: removeDots(JSON.parse(comparaison_tag.dataset.points)[2]),
				options: {
					plugins: {
						datalabels: {
							anchor: 'end',
							align: function (context) {
								return context.dataset.data[context.dataIndex] < 80 ? 'end' : 'start';
							},
							color: function (context) {
								const index = context.dataIndex
								const lab = context.dataset.label
								if (lab === labelUn) {
                  const color = context.dataset.data[index] < 80 ? '#5d78ff' : 'white';
                  return color;
                } else {
									return '#5d78ff';
                }
							},
              formatter: function (value, context) {
                if (value) {
                  let percentage = Math.round(value) + "%";
                  return percentage;
                }
                return '';
              },
						},
					},
					scales: {
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks: {
								suggestedMin: 0,
								suggestedMax: 10
							}
						}],
						yAxes: [{
							ticks: {
								display: true,
								callback: function(value) {
									return (value.length < 25 ? value : value.substr(0, 25) + '...');
								},
							},
							gridLines: {
								drawBorder: false,
								display: false
							}
						}]
					},
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
                if (value !== 0) {
                  return datasetLabel + ': ' + Math.round(value) + '%';
                }
              },
            },
          },

				}
			});

			let chart = comparaison_items
			chart.options.animation = false;

			//console.log(comparaison_items.data.labels);
			//console.log(comparaison_items.data.labels[0]);
			//console.log(comparaison_items.data.labels[0]);
			//console.log(typeof comparaison_items.data.labels[0]);
			//if (comparaison_items.data.labels[0] == 'NaN undefined' || comparaison_items.data.labels[0] == 'S NaN/NaN' || comparaison_items.data.labels[0] == 'undefined NaN' || `${comparaison_items.data.labels[0]}` == 'NaN') {
				//location.reload();
			//}

			// FT pour acceder au raport d'Un (1) produit. A decommenter pour acceder à la page

			comparaison_tag.onclick = function (e) {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const enquete = urlParams.get('enquete[]');
        let enquete_params = ""
        if (enquete) {
          enquete_params = `&enquete[]=${enquete}`
        }

			  triggerLoadingDots(loadingDotsContainer);

				let section = comparaison_items.getElementAtEvent(e);
				if (!section.length) return; // return if not clicked on section
				let label = section[0]._model.label;
				let datasetLabel = section[0]._model.datasetLabel;
				// add case for each label/section
				const ids = comparaison_tag.dataset.points_ids.split("[").pop().split("]").shift().split(",");
				let i = 0;
				comparaison_items.data.labels.forEach(function (element) {

					if (label === element) {
            console.log(enquete_params)
						if (document.querySelector(".categories-index-gerant-for-js")) {
							const group = document.querySelector(".categories-index-gerant-for-js").dataset.group
							const meals = comparaison_tag.dataset.points_second_ids.split("[").pop().split("]").shift().split(",");
              if (comparaison_tag.dataset.enquete) {
                window.open(`/${locale}/groups/${group}/meal_multisite?second_id=${meals[i].replace(/"/, '').replace(/"/, '')}&${enquete_params}`, '_self');
              } else {
                window.open(`/${locale}/groups/${group}/meal_multisite?second_id=${meals[i].replace(/"/, '').replace(/"/, '')}`, '_self');
              }
						}
					}

					i = i + 1;
				})
			}
		}


		//Graphe repartition serveurs
		const noteSatisfactionservice = document.getElementById("evolution-satisfaction-service");

		if (noteSatisfactionservice) {
			note_satisfaction_service = new Chart(noteSatisfactionservice, {
				type: 'bar',
				data: {
					labels: JSON.parse(noteSatisfactionservice.dataset.points_em),
					datasets: [{
						label: 'Moyenne avis ',
						//'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
						data: JSON.parse(noteSatisfactionservice.dataset.points),
						backgroundColor: [
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
							'#5D79FF',
						],
						hoverBackgroundColor: [
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',

						],
						borderColor: [
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
						],
						borderWidth: 1
					}]
				},
				options: {
					plugins: {
						datalabels: {
							anchor: 'end',
							align: function (context) {
								return context.dataset.data[context.dataIndex] < 8 ? 'top' : 'start';
							},
							color: function (context) {
								return context.dataset.data[context.dataIndex] < 8 ? '#5d78ff' : 'white';
							},
						}
					},
					legend: {
						display: false
					},
					scales: {
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks: {
								callback: function(value) {
									return (value.length < 25 ? value : value.substr(0, 25) + '...');
								},
							}
						}],
						yAxes: [{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 10
							},
							gridLines: {
								drawBorder: false,
								display: false
							}
						}]
					},
				}
			});

			let chart = note_satisfaction_service
			chart.options.animation = false;

			console.log(note_satisfaction_service.data.labels);
			console.log(note_satisfaction_service.data.labels[0]);
			console.log(typeof note_satisfaction_service.data.labels[0]);

			if (note_satisfaction_service.data.labels[0] == 'NaN undefined' || note_satisfaction_service.data.labels[0] == 'S NaN/NaN' || note_satisfaction_service.data.labels[0] == 'undefined NaN' || `${note_satisfaction_service.data.labels[0]}` == 'NaN') {
				location.reload();
			}

			noteSatisfactionservice.onmouseover = function (e) {
				document.body.style.cursor = "pointer";

			}

			noteSatisfactionservice.onmouseout = function () {
				document.body.style.cursor = "";
			}


			noteSatisfactionservice.onclick = function (e) {
				let section = note_satisfaction_service.getElementAtEvent(e);
				if (!section.length) return; // return if not clicked on section
				let label = section[0]._model.label;
				// add case for each label/section

				let i = 0;
				note_satisfaction_service.data.labels.forEach(function (element) {
					// "DÉTAIL DES AVIS PAR SERVEUR" Rapport resto serveur
					if (label === element) {
						if (document.querySelector(".employee-satis-service-for-js")) {
							const ids = noteSatisfactionservice.dataset.points_id.split("[").pop().split("]").shift().split(",");
							const group = document.querySelector(".resto").dataset.group
							const sg = document.querySelector(".resto").dataset.sg
							const groupe_question = document.querySelector("#evolution-satisfaction-service").dataset.gqId
							let allEnquete = ""
							if (document.querySelector("#evolution-satisfaction-service").dataset.allEnquete !== "") {
								allEnquete = `all_enquete=${document.querySelector("#evolution-satisfaction-service").dataset.allEnquete}`
							}
							else if (!(window.location.href.includes("all_enquete")) && !(window.location.href.includes("enquete"))) {
								allEnquete = "all_enquete=1"
							}
							const enquete = document.querySelector("#evolution-satisfaction-service").dataset.enquete.split("[").pop().split("]").shift().replaceAll('"', '').replaceAll(',', '')
							switch (format) {
								case 'Jour':
								case 'Day':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${groupe_question}/${locale}/day_variable_new_survey_v2?${allEnquete}&employee=${ids[i]}&enquete=${enquete}&date=${dateTag.value}`, '_self');
									break;
								case 'Semaine':
								case 'Week':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${groupe_question}/${locale}/week_variable_new_survey_v2?${allEnquete}&employee=${ids[i]}&enquete=${enquete}&date=${dateTag.value}`, '_self');
									break;
								case 'Mois':
								case 'Month':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${groupe_question}/${locale}/month_variable_new_survey_v2?${allEnquete}&employee=${ids[i]}&enquete=${enquete}&date=${dateTag.value}`, '_self');
									break;
								case 'Année':
								case 'Year':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${groupe_question}/${locale}/year_variable_new_survey_v2?${allEnquete}&employee=${ids[i]}&enquete=${enquete}&date=${dateTag.value}`, '_self');
									break;
								case 'Cumul':
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${groupe_question}/${locale}/cumul_variable_new_survey_v2?${allEnquete}&employee=${ids[i]}&enquete=${enquete}&date=${dateTag.value}`, '_self');
									break;
								default:
									window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/groupe_questions/${groupe_question}/${locale}/day_variable_new_survey_v2?${allEnquete}&employee=${ids[i]}&enquete=${enquete}&date=${dateTag.value}`, '_self');
							}
						}
						// "DÉTAIL DES AVIS PAR ETABLISSEMENT" Rapport employee index_gerant
					} else if (document.querySelector(".employee-satis-service-index-gerant-for-js")) {
						const group = document.querySelector(".employee-satis-service-index-gerant-for-js").dataset.group
						const ids = noteSatisfactionservice.dataset.points_id.split("[").pop().split("]").shift().split(",");
						const sgs = noteSatisfactionservice.dataset.points_sg_id.split("[").pop().split("]").shift().split(",");
						switch (format) {
							case 'Jour':
							case 'Day':
								window.open(`/groups/${group}/sous_groupes/${sgs[i]}/${locale}/restaurants/${ids[i]}/employees/day_all?date=${dateTag.value}`, '_self');
								break;
							case 'Semaine':
							case 'Week':
								window.open(`/groups/${group}/sous_groupes/${sgs[i]}/${locale}/restaurants/${ids[i]}/employees/week_all?date=${dateTag.value}`, '_self');
								break;
							case 'Mois':
							case 'Month' :
								window.open(`/groups/${group}/sous_groupes/${sgs[i]}/${locale}/restaurants/${ids[i]}/employees/month_all?date=${dateTag.value}`, '_self');
								break;
							case 'Année':
							case 'Year':
								window.open(`/groups/${group}/sous_groupes/${sgs[i]}/${locale}/restaurants/${ids[i]}/employees/year_all?date=${dateTag.value}`, '_self');
								break;
							case 'Cumul':
								window.open(`/groups/${group}/sous_groupes/${sgs[i]}/${locale}/restaurants/${ids[i]}/employees/cumul_all?date=${dateTag.value}`, '_self');
								break;
							default:
								window.open(`/groups/${group}/sous_groupes/${sgs[i]}/${locale}/restaurants/${ids[i]}/employees/day_all?date=${dateTag.value}`, '_self');
						}
					}

					i = i + 1;

				})
				// add rests ...
			}
		}

		// Comparaison de la satisfaction pour un établissement entre plusieurs enquêtes     ET  comparaison satisfaction entre plusieurs enquêtes multisite
		const comparatifEnquetes = document.getElementById("chart-comparatif-enquetes");

		if (comparatifEnquetes) {
			console.log("comparatifEnquetes")
			note_satisfaction_enquetes = new Chart(comparatifEnquetes, {
				type: 'bar',
				data: {
					labels: JSON.parse(comparatifEnquetes.dataset.points_em),
					datasets: [{
						label: 'Moyenne avis ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
						data: JSON.parse(comparatifEnquetes.dataset.points),
						backgroundColor: '#5D79FF',
						hoverBackgroundColor: [
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
						],
						borderColor: [
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)',
						],
						borderWidth: 1
					}]
				},
				options: {
					legend: {
						display: false
					},
					scales: {
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks: {
								callback: function(value) {
									return (value.length < 25 ? value : value.substr(0, 25) + '...');
								},
							}
						}],
						yAxes: [{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 10
							},
							gridLines: {
								drawBorder: false,
								display: false
							}
						}]
					},

					plugins: {
						datalabels: {
							anchor: 'end',
							align: function (context) {
								return context.dataset.data[context.dataIndex] < 8 ? 'top' : 'start';
							},
							color: function (context) {
								return context.dataset.data[context.dataIndex] < 8 ? '#5d78ff' : 'white';
							},
							// display labels with a value greater than 0
							display: function(context) {
									const index = context.dataIndex;
									const value = context.dataset.data[index];
									return value > 0;
							},
						}
					}
				}
			});

			let chart = note_satisfaction_enquetes
			chart.options.animation = false;

			if (note_satisfaction_enquetes.data.labels[0] == 'NaN undefined' || note_satisfaction_enquetes.data.labels[0] == 'S NaN/NaN' || note_satisfaction_enquetes.data.labels[0] == 'undefined NaN' || `${note_satisfaction_enquetes.data.labels[0]}` == 'NaN') {
				location.reload();
			}

			comparatifEnquetes.onmouseover = function (e) {
				document.body.style.cursor = "pointer";

			}

			comparatifEnquetes.onmouseout = function () {
				document.body.style.cursor = "";
			}


			comparatifEnquetes.onclick = function (e) {
				let section = note_satisfaction_enquetes.getElementAtEvent(e);

				if (!section.length) return; // return if not clicked on section
				let label = section[0]._model.label;
				// add case for each label/section
				const ids = comparatifEnquetes.dataset.points_id.split("[").pop().split("]").shift().split(",");

				const titre = document.querySelector(".title").innerText;
				let i = 0;
				note_satisfaction_enquetes.data.labels.forEach(function (element) {
					if (label === element) {
						const group = document.querySelector(".resto").dataset.group
						const sg = document.querySelector(".resto").dataset.sg
						switch (format) {
							case 'Jour':
							case 'Day':
								window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day?enquete=${ids[i]}&date=${new Date(date)}`, '_self');
								date = new Date(convertDate(dateTag.value));
								break;
							case 'Semaine':
							case 'Week':

								window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/week?enquete=${ids[i]}&date=${new Date(date)}`, '_self');
								date = new Date(convertDate(dateTag.value));
								break;
							case 'Mois':
							case 'Month':

								window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/month?enquete=${ids[i]}&date=${new Date(date)}`, '_self');
								date = new Date(convertDate(dateTag.value));
								break;
							case 'Année':
							case 'Year':

								window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/year?enquete=${ids[i]}&date=${new Date(date)}`, '_self');
								date = new Date(convertDate(dateTag.value));
								break;
							case 'Cumul':

								window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/cumul?enquete=${ids[i]}&date=${new Date(date)}`, '_self');
								date = new Date(convertDate(dateTag.value));
								break;
							default:

								window.open(`/groups/${group}/sous_groupes/${sg}/restaurants/${resto.text}/${locale}/day?enquete=${ids[i]}&date=${new Date(date)}`, '_self');
								date = new Date(convertDate(dateTag.value));
						}
					}


					i = i + 1;
				})
			}
		}

		// Chart Evolution Note google / facebook
		const evolutionNotePage = document.querySelectorAll(".evolution-note-chart");

		if (evolutionNotePage) {
			Array.prototype.forEach.call(evolutionNotePage, function (evo_note) {
				let tempFormat = format
				let en_format = false

				if (["Day", "Week", "Month", "Year"].includes(tempFormat)) {en_format = true}
				let labels = ["", "", ""]
				if (evo_note.classList.contains("five-day")) {
					format = "Jour";
					if (en_format) {
						format = "Day";
					}
					labels = createLabelsArray(date, format);
					let labels_reverse = labels.reverse();
					labels = [labels_reverse[2], labels_reverse[1], labels_reverse[0]]
				}
				if (evo_note.classList.contains("five-week")) {
					format = "Semaine";
					if (en_format) {
						format = "Week";
					}
					labels = createLabelsArray(date, format);
					let labels_reverse = labels.reverse();
					labels = [labels_reverse[2], labels_reverse[1], labels_reverse[0]]
				}
				if (evo_note.classList.contains("five-month")) {
					format = "Mois";
					if (en_format) {
						format = "Month";
					}
					labels = createLabelsArray(date, format);
					let labels_reverse = labels.reverse();
					labels = [labels_reverse[2], labels_reverse[1], labels_reverse[0]]
				}
				if (evo_note.classList.contains("five-year")) {
					format = "Année";
					if (en_format) {
						format = "Year";
					}
					labels = createLabelsArray(date, format);
					let labels_reverse = labels.reverse();
					labels = [labels_reverse[2], labels_reverse[1], labels_reverse[0]]
				}
				format = tempFormat;
				let dataset = []

				if (!(JSON.parse(evo_note.dataset.points_fb).every(element => element === null))) {
					dataset.push({
						label: "Facebook",
						backgroundColor: [
							'#e5e7f1',
							'#e5e7f1',
							'#F35369'
						],
						hoverBackgroundColor: [
							'#F35369',
							'#F35369',
							'#F35369'
						],
						data: JSON.parse(evo_note.dataset.points_fb)
					})
				}
				if (!(JSON.parse(evo_note.dataset.points_google).every(element => element === null))) {
					dataset.push({
						label: "Google",
						backgroundColor: [
							'#e5e7f1',
							'#e5e7f1',
							'#F9BB07'
						],
						hoverBackgroundColor: [
							'#F9BB07',
							'#F9BB07',
							'#F9BB07'
						],
						data: JSON.parse(evo_note.dataset.points_google)
					})
				}
				if (!(JSON.parse(evo_note.dataset.points_google).every(element => element === null)) && !(JSON.parse(evo_note.dataset.points_fb).every(element => element === null))) {
					dataset.push({
						label: "Total",
						backgroundColor: [
							'#e5e7f1',
							'#e5e7f1',
							'#5d78ff'
						],
						hoverBackgroundColor: [
							'rgb(81,138,268)',
							'rgb(81,138,268)',
							'rgb(81,138,268)'
						],
						data: JSON.parse(evo_note.dataset.points_total)
					})
				}
				const evo_note_page = new Chart(evo_note, {
						type: 'bar',
						data: {
							labels: labels,
							datasets: dataset
							// pour fixer le gadriage et les axes du graphe
						},
						options: {
							legend: {
								display: false
							},
							scales: {
								xAxes: [{
									gridLines: {
										display: false
									},
								}],
								yAxes: [{
									ticks: {
										display: true,
										stepSize: 1,
										suggestedMin: 0,
										suggestedMax: 5,
										callback: function(label) {
											if (document.URL.includes("/scan_my_menu")) {
												// Adding a space between numbers: "10000" will become "10 000".
												return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
											} else {
												return label;
											}
										}
									},
									gridLines: {
										drawBorder: false,
										display: false
									},
								}]
							},
							plugins: {
								datalabels: {
									anchor: 'end',
									align: function (context) {
										return context.dataset.data[context.dataIndex] < 4 ? 'top' : 'start';
									},
									color: function (context) {
										return context.dataset.data[context.dataIndex] < 4 ? ['#5d78ff', '#5d78ff', '#5d78ff'] : ['#5d78ff', '#5d78ff', 'white']
									},
								},
							}
						},



				})

				let chart = evo_note_page
				chart.options.animation = false;
			})

		}
	}

	const createArrayColor = (labels) => {
		let colorArray = [];

		const combi = [
			["Bronze", "Gold", "New", "Silver"],
			["Bronze", "Gold", "Silver"],
			["Gold", "New", "Silver"],
			["Bronze", "Gold", "New"],
			["Bronze", "New", "Silver"],
			["Gold", "Silver"],
			["Bronze", "Gold"],
			["Gold", "New"],
			["Bronze", "Silver"],
			["New", "Silver"],
			["Bronze", "New"],
			["Gold"],
			["Silver"],
			["Bronze"],
			["New"],
		];

		const color = [
			["#614E1A", "#c49c48", "#f4f3f3", "#A5A49B"],
			["#614E1A", "#c49c48", "#A5A49B"],
			["#c49c48", "#f4f3f3", "#A5A49B"],
			["#614E1A", "#c49c48", "#f4f3f3"],
			["#614E1A", "#f4f3f3", "#A5A49B"],
			["#c49c48", "#A5A49B"],
			["#614E1A", "#c49c48"],
			["#c49c48", "#f4f3f3"],
			["#614E1A", "#A5A49B"],
			["#f4f3f3", "#A5A49B"],
			["#614E1A", "#f4f3f3"],
			["#c49c48"],
			["#A5A49B"],
			["#614E1A"],
			["#f4f3f3"],
		];

		let i = 0;

		Array.prototype.forEach.call(combi, function (c) {
			if (JSON.stringify(labels) == JSON.stringify(c)) {
				colorArray = color[i];
			}

			i = i + 1;
		})
		return colorArray;
	}


	//RÉPARTITION DU TYPE DE MA CLIENTÈLE
	const repartition = document.getElementById("repartition-fidelite");

	if (repartition) {
		const backgroundColorA = createArrayColor(JSON.parse(repartition.dataset.points_em));


		const repartition_fidelite = new Chart(repartition, {
			type: 'pie',
			data: {
				labels: JSON.parse(repartition.dataset.points_em),
				datasets: [{
					data: JSON.parse(repartition.dataset.points),

					backgroundColor: backgroundColorA,
					hoverBackgroundColor: backgroundColorA,
					borderColor: backgroundColorA,
					borderWidth: 1
				}]
			},
			options: {
				legend: {
					position: 'bottom',
				},
				plugins: {
					datalabels: {
						formatter: (value, ctx) => {
							let sum = 0;
							let dataArr = ctx.chart.data.datasets[0].data;
							dataArr.map(data => {
								sum += data;
							});
							let percentage = (value * 100 / sum).toFixed(0) + "%";
							return percentage;
						},
						color: '#232947',
					},
				},
			}
		});

		let chart = repartition_fidelite
		chart.options.animation = false;

		console.log(repartition_fidelite.data.labels);
		console.log(repartition_fidelite.data.labels[0]);
		console.log(typeof repartition_fidelite.data.labels[0]);

		if (repartition_fidelite.data.labels[0] == 'NaN undefined' || repartition_fidelite.data.labels[0] == 'S NaN/NaN' || repartition_fidelite.data.labels[0] == 'undefined NaN' || `${repartition_fidelite.data.labels[0]}` == 'NaN') {

			location.reload();
		}
	}


	// EVOLUTION FIDELITE periode
	const evolutionFidelite = document.getElementById("evolution-fidelite");

	if (evolutionFidelite) {
		const note_satisfaction_service = new Chart(evolutionFidelite, {
			type: 'bar',
			data: {
				labels: JSON.parse(evolutionFidelite.dataset.points_em),
				datasets: [{
					label: 'Nb de clients ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
					data: JSON.parse(evolutionFidelite.dataset.points),
					backgroundColor: [
						'#c49c48',
						'#A5A49B',
						'#614E1A',
						'#f4f3f3',
					],
					hoverBackgroundColor: [
						'#c49c48',
						'#A5A49B',
						'#614E1A',
						'#f4f3f3',
					],

					borderColor: [
						'#c49c48',
						'#A5A49B',
						'#614E1A',
						'#f4f3f3',
					],

					borderWidth: 1
				}]
			},
			options: {
				plugins: {
					datalabels: {
						anchor: 'end',
						align: function (context) {
							return context.dataset.data[context.dataIndex] < (JSON.parse(evolutionFidelite.dataset.points_em).length) ? 'top' : 'start';
						},
						color: '#232947',
					}
				},

				legend: {
					display: false
				},
				scales: {
					xAxes: [{
						gridLines: {
							display: false
						}
					}],
					yAxes: [{
						ticks: {
							display: true,
							suggestedMin: 0,
							suggestedMax: 10
						},
						gridLines: {
							drawBorder: false,
							display: false
						}
					}]
				}
			}
		});

		let chart = note_satisfaction_service
		chart.options.animation = false;

		console.log(note_satisfaction_service.data.labels);
		console.log(note_satisfaction_service.data.labels[0]);
		console.log(typeof note_satisfaction_service.data.labels[0]);

		if (note_satisfaction_service.data.labels[0] == 'NaN undefined' || note_satisfaction_service.data.labels[0] == 'S NaN/NaN' || note_satisfaction_service.data.labels[0] == 'undefined NaN' || `${note_satisfaction_service.data.labels[0]}` == 'NaN') {

			location.reload();
		}
	}



	//Graphe comparaison des différents rewards

	const removeDotsa = (array) => {
		return array.map(word => word.split(".").join(" "));
	};

	const rewards_tag = document.getElementById("comparaison-rewards");

	if (rewards_tag) {

		const verticalBarChartData = {
			labels: removeDotsa(JSON.parse(rewards_tag.dataset.points)[2]),
			datasets: [{
				label: 'Récompenses distribuées ',
				backgroundColor: '#5d78ff',
				borderColor: '#5d78ff',
				borderWidth: 1,
				data: JSON.parse(rewards_tag.dataset.points)[0],
			}, {
				label: 'Récompenses utilisées',
				backgroundColor: '#e5e7f1',
				borderColor: '#e5e7f1',
				data: JSON.parse(rewards_tag.dataset.points)[1]
			}]
		};

		comparaison_items_rewards = new Chart(rewards_tag, {
			type: 'bar',
			data: verticalBarChartData,
			labels: removeDotsa(JSON.parse(rewards_tag.dataset.points)[2]),
			options: {
				plugins: {
					datalabels: {
						anchor: 'end',
						align: function (context) {
							return context.dataset.data[context.dataIndex] < 50 ? 'top' : 'start';
						},

						color: function (context) {
							const index = context.dataIndex
							const lab = context.dataset.label
							if (lab === 'Récompenses distribuées ')
								return context.dataset.data[index] < 50 ? '#5d78ff' : 'white';
							else
								return context.dataset.data[index] < 50 ? '#5d78ff' : '#5d78ff';
							end
						},
					},
				},
				legend: {
					position: 'bottom',
				},
				scales: {
					xAxes: [{
						gridLines: {
							display: false
						},
						ticks: {
							suggestedMin: 0,
						}
					}],
					yAxes: [{
						ticks: {
							suggestedMin: 0,
							fixedStepSize: 1,
							display: true
						},
						gridLines: {
							drawBorder: false,
							display: false
						}
					}]
				},

			}
		});

		let chart = comparaison_items_rewards
		chart.options.animation = false;

		console.log(comparaison_items_rewards.data.labels);
		console.log(comparaison_items_rewards.data.labels[0]);
		console.log(typeof comparaison_items_rewards.data.labels[0]);

		if (comparaison_items_rewards.data.labels[0] == 'NaN undefined' || comparaison_items_rewards.data.labels[0] == 'S NaN/NaN' || comparaison_items_rewards.data.labels[0] == 'undefined NaN' || `${comparaison_items_rewards.data.labels[0]}` == 'NaN') {

			location.reload();
		}
	}


	//Graphe comparaison des différentes cartes de fidélité

	const progressCarteFid = document.getElementById("comparaison-fid");

	if (progressCarteFid) {
		const progress_carte_fid_percent = new Chart(progressCarteFid, {
			type: 'bar',
			data: {
				labels: ['100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%'],
				datasets: [{
					label: 'Nombre Carte Fid en % avancement', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'

					data: JSON.parse(progressCarteFid.dataset.points),
					backgroundColor: [
						'#5d78ff',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
					],

					hoverBackgroundColor: [
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
						'rgb(81,138,268)',
					],

					borderColor: [
						'#5d78ff',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
						'#e5e7f1',
					],

					hoverBorderColor: [
						'#6b76ff',
						'#5ca4e3',
						'#5ca4e3',
						'#5ca4e3',
						'#5ca4e3',
						'#5ca4e3',
						'#5ca4e3',
						'#5ca4e3',
						'#5ca4e3',
						'#5ca4e3',
					],

					borderWidth: 1
				}]
				// pour fixer le gadriage et les axes du graphe
			},
			options: {
				legend: {
					display: false
				},
				scales: {
					xAxes: [{
						gridLines: {
							display: false
						},
					}],
					yAxes: [{
						ticks: {
							display: true,
						},
						gridLines: {
							drawBorder: false,
							display: false
						},
					}]
				},
				plugins: {
					datalabels: {
						anchor: 'end',
						align: function (context) {
							return context.dataset.data[context.dataIndex] == 0 ? 'end' : 'start';
						},
						color: function (context) {
							return context.dataset.data[context.dataIndex] == 0 ? ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'] : ['#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff'];
						},
					},
				}
			},

		});

		let chart = progress_carte_fid_percent
		chart.options.animation = false;


		console.log(progress_carte_fid_percent.data.labels);
		console.log(progress_carte_fid_percent.data.labels[0]);
		console.log(typeof progress_carte_fid_percent.data.labels[0]);

		if (progress_carte_fid_percent.data.labels[0] == 'NaN undefined' || progress_carte_fid_percent.data.labels[0] == 'S NaN/NaN' || progress_carte_fid_percent.data.labels[0] == 'undefined NaN' || `${progress_carte_fid_percent.data.labels[0]}` == 'NaN') {
			location.reload();
		}
	}

	//Graphe Pie Clients
	const repartition_customer = document.querySelectorAll(".repartition-client");

	if (repartition_customer) {
		let backgroundColor = []
		let hoverBackgroundColor = []
		let borderColor = []

		// Array of divs to get all data-color
		const arrayColorsDivs = document.querySelectorAll('.repartition-client')
		let i = 0

		Array.prototype.forEach.call(repartition_customer, function (customer) {

			let arrayColors = arrayColorsDivs[i].dataset.color.split(', ') //String turned into an array
			i++
			// A MODIFIER PLUS TARD ! QUICK FIX POUR LES COULEURS ⬇️
      if (customer.dataset.type_question == 'Tag Degrés' || customer.dataset.type_question == 'Tag Satisfaction') {
				// Colors depends of the order of question's possible answers
				backgroundColor = [
					arrayColors[0],
					arrayColors[1],
					arrayColors[2],
					arrayColors[3],
					arrayColors[4],
				]

				hoverBackgroundColor = [
					arrayColors[0],
					arrayColors[1],
					arrayColors[2],
					arrayColors[3],
					arrayColors[4],
				]

				borderColor = [
					arrayColors[0],
					arrayColors[1],
					arrayColors[2],
					arrayColors[3],
					arrayColors[4],
				]
				// A MODIFIER PLUS TARD ! QUICK FIX POUR LES COULEURS ⬇️
      } else if (customer.dataset.type_question == 'Oui Non' && JSON.parse(customer.dataset.points_em).length === 3) {

					backgroundColor = [
						'#1dc9b7', //tag oui - vert
					'#fd397a', //tag non - rouge
					'#5e78fb', //3eme tag - bleu
				]

				hoverBackgroundColor = [
					'#1dc9b7', //tag oui - vert
					'#fd397a', //tag non - rouge
					'#5e78fb', //3eme tag - bleu
				]


				borderColor = [
					'#1dc9b7', //tag oui - vert
					'#fd397a', //tag non - rouge
					'#5e78fb', //3eme tag - bleu
				]

				// A MODIFIER PLUS TARD ! QUICK FIX POUR LES COULEURS ⬇️
      } else if (customer.dataset.type_question == 'Oui Non' && JSON.parse(customer.dataset.points_em).length === 2) {
				backgroundColor = [
					'#1dc9b7', //tag oui - vert
					'#fd397a', //tag non - rouge
				]

				hoverBackgroundColor = [
					'#1dc9b7', //tag oui - vert
					'#fd397a', //tag non - rouge
				]

				borderColor = [
					'#1dc9b7', //tag oui - vert
					'#fd397a', //tag non - rouge
				]
			}

			else {
				backgroundColor = [
					'#6C6EA0',
					'#66C7F4',
					'#C1CAD6',
					'#86BBBD',
					'#544E61',
					'#76949F',
					'#85BAA1',
					'#CEEDDB',
					'#F9B5AC',
					'#D7CDCC',
					'#59656F',
					'#F2B880',
					'#533747',
					'#D0D6B5',
					'#034078',
					'#7698B3',
					'#7F557D',
					'#1282A2',
					'#001F54',
					'#E7CFBC',
				]
				hoverBackgroundColor = [
					'#6C6EA0',
					'#66C7F4',
					'#C1CAD6',
					'#86BBBD',
					'#544E61',
					'#76949F',
					'#85BAA1',
					'#CEEDDB',
					'#F9B5AC',
					'#D7CDCC',
					'#59656F',
					'#F2B880',
					'#533747',
					'#D0D6B5',
					'#034078',
					'#7698B3',
					'#7F557D',
					'#1282A2',
					'#001F54',
					'#E7CFBC',
				]
				borderColor = [
					'#6C6EA0',
					'#66C7F4',
					'#C1CAD6',
					'#86BBBD',
					'#544E61',
					'#76949F',
					'#85BAA1',
					'#CEEDDB',
					'#F9B5AC',
					'#D7CDCC',
					'#59656F',
					'#F2B880',
					'#533747',
					'#D0D6B5',
					'#034078',
					'#7698B3',
					'#7F557D',
					'#1282A2',
					'#001F54',
					'#E7CFBC',
				]
			}

      let labels = JSON.parse(customer.dataset.points_em)
      // let labels = ["Toujours salarié de mon entreprise, au même poste", "Toujours salarié de mon entreprise, mais pas au même poste (mobilité à un autre poste, dans un autre centre…)", "Toujours dans le même poste, mais ailleurs que chez Efficience Santé au Travail", "Dans un autre poste, ailleurs que chez Efficience Santé au Travail", "A la retraite", "Autre"]
      let data = JSON.parse(customer.dataset.points)

      const config = {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: backgroundColor,
            hoverBackgroundColor: hoverBackgroundColor,
            borderColor: borderColor,
            borderWidth: 1
          }]
        },
        options: {
          cutoutPercentage: 30,
          legend: {
            position: 'right',
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let sumu = 0;
                let dataArra = ctx.chart.data.datasets[0].data;
                dataArra.map(data => {
                  sumu += data;
                });
                if (value > 0) {
                  let percentages = (value * 100 / sumu).toFixed(0) + "%";
                  return percentages;
                }
                else {
                  return "";
                }
              },
              color: [
                'white',
                '#232947',
                '#232947',
                '#232947',
                'white',
                'white',
                '#232947',
                '#232947',
                '#232947',
                '#232947',
                'white',
                '#232947',
                'white',
                '#232947',
                'white',
                'white',
                'white',
                'white',
                'white',
                '#232947',
              ],
            },
          },
        },

      }


      labels.forEach(element => {
        if (element.length > 30) {
          config.options.legend.align = 'start';
          config.options.legend.position = 'bottom';
        }
      });


			const repartition_client = new Chart(customer, config);


			let chart = repartition_client
			chart.options.animation = false;

			console.log(repartition_client.data.labels);
			console.log(repartition_client.data.labels[0]);
			console.log(typeof repartition_client.data.labels[0]);

			if (repartition_client.data.labels[0] == 'NaN undefined' || repartition_client.data.labels[0] == 'S NaN/NaN' || repartition_client.data.labels[0] == 'undefined NaN' || `${repartition_client.data.labels[0]}` == 'NaN' || repartition_client.data.labels[0] == 'NaN%') {

				location.reload();
			}
		})

	}

	// DESKTOP Rapport meal_all_index_gerant, graphe: DÉTAIL PAR ÉTABLISSEMENT ET PAR CATÉGORIE
	if (document.getElementById('chart-detail-per-categ-for-all-resto')) {
		var ctx = document.getElementById('chart-detail-per-categ-for-all-resto').getContext('2d');
		var categories = document.getElementById('chart-detail-per-categ-for-all-resto').dataset.categories
		var restaurantsLabel = document.getElementById('chart-detail-per-categ-for-all-resto').dataset.restaurantsLabel
		const arrayColor = [
			'rgba(255, 99, 132, 0.2)',
			'rgba(54, 162, 235, 0.2)',
			'rgba(255, 206, 86, 0.2)',
			'rgba(75, 192, 192, 0.2)',
			'rgba(153, 102, 255, 0.2)',
			'rgba(255, 159, 64, 0.2)'
		]

		const backgroundColor = [
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 206, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 159, 64, 1)'
		]

		let i = 0
		const datasetsArray = []
		JSON.parse(categories).forEach((categ) => {
			datasetsArray.push(
				{
					label: `${categ}`,
					data: JSON.parse(document.getElementById('chart-detail-per-categ-for-all-resto').dataset.points_em)[i],
					backgroundColor: arrayColor[i],
					borderColor: backgroundColor[i],
					borderWidth: 1,
					hidden: i === 0 ? false : true
				})
			i += 1
		})

		var myChart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: JSON.parse(restaurantsLabel),
				datasets: datasetsArray
			},
			options: {
				legend: {
					display: true
				},
				scales: {
					xAxes: [{
						gridLines: {
							display: false
						},
					}],
					yAxes: [{
						ticks: {
							beginAtZero: true
						},
						gridLines: {
							drawBorder: false,
							display: false
						},
					}]
				}
			}

		});

		let chart = myChart
		chart.options.animation = false;
	}


	// MOBILE Rapport meal_all_index_gerant, graphe: DÉTAIL PAR ÉTABLISSEMENT ET PAR CATÉGORIE
	if (document.getElementById('chart-detail-per-categ-for-all-resto-mobile')) {
		var ctx = document.getElementById('chart-detail-per-categ-for-all-resto-mobile').getContext('2d');
		var categories = document.getElementById('chart-detail-per-categ-for-all-resto-mobile').dataset.categories
		var restaurantsLabel = document.getElementById('chart-detail-per-categ-for-all-resto-mobile').dataset.restaurantsLabel
		const arrayColor = [
			'rgba(255, 99, 132, 0.2)',
			'rgba(54, 162, 235, 0.2)',
			'rgba(255, 206, 86, 0.2)',
			'rgba(75, 192, 192, 0.2)',
			'rgba(153, 102, 255, 0.2)',
			'rgba(255, 159, 64, 0.2)'
		]

		const backgroundColor = [
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 206, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 159, 64, 1)'
		]


		let i = 0
		const datasetsArray = []
		JSON.parse(categories).forEach((categ) => {
			datasetsArray.push(
				{
					label: `${categ}`,
					data: JSON.parse(document.getElementById('chart-detail-per-categ-for-all-resto-mobile').dataset.points_em)[i],
					backgroundColor: arrayColor[i],
					borderColor: backgroundColor[i],
					borderWidth: 1,
					hidden: i === 0 ? false : true
				})
			i += 1
		})


		var myChart = new Chart(ctx, {
			type: 'horizontalBar',
			data: {
				labels: JSON.parse(restaurantsLabel),
				datasets: datasetsArray
			},
			options: {
				legend: {
					display: true
				},
				scales: {
					xAxes: [{
						gridLines: {
							display: false
						},
					}],
					yAxes: [{
						ticks: {
							beginAtZero: true
						},
						gridLines: {
							drawBorder: false,
							display: false
						},
					}]
				}
			}

		});

		let chart = myChart
		chart.options.animation = false;
	}





	if (document.querySelector(".chart-new-surv-v2-multiple-choice-tag")) {
		console.log("initChartJS chart-new-surv-v2-multiple-choice-tag")

		const multipleTagChoiceBars = document.querySelectorAll(".chart-new-surv-v2-multiple-choice-tag")
		multipleTagChoiceBars.forEach((multipleTagChoiceBar) => {
			const typeQuestion = multipleTagChoiceBar.dataset.typeQuestion
			let color = ""
			if (typeQuestion == "Tag Positif") {
				color = 'rgba(89, 202, 184, 1)' //  vert
			} else if (typeQuestion == "Tag Négatif") {
				color = 'rgba(255, 99, 132, 1)' // rouge
			} else {
				color = 'rgba(93, 120, 251, 1)' //  bleu
			}
      const dataPoints = JSON.parse(multipleTagChoiceBar.dataset.points_em);
      const maxValue = Math.max(...dataPoints);

			var myBarChart = new Chart(multipleTagChoiceBar, {
				type: 'horizontalBar',
				data: {
					labels: JSON.parse(multipleTagChoiceBar.dataset.restaurantsLabel),
					datasets: [{
            data: dataPoints,
						backgroundColor: color,
						hoverBackgroundColor: color,
						borderWidth: 1,
					}]
				},

				options: {
					legend: {
						display: false,
					},

					plugins: {
						datalabels: {
							formatter: (value, ctx) => {
								let percentage = (value).toFixed(1) + "%";
								return percentage;
							},
							anchor: 'end',
							align: function (context) {
                return context.dataset.data[context.dataIndex] >= (maxValue - 5) ? 'start' : 'end';
							},
							color: function (context) {
                return context.dataset.data[context.dataIndex] >= (maxValue - 5) ? 'white' : 'black';
							},
						}
					},

					scales: {
						xAxes: [{
							ticks: {
								beginAtZero: true,
                //max: 100,
								callback: function (value) {
									return (value).toFixed(0) + '%'; // convert it to percentage
								},
							},

							gridLines: {
								display: false
							},
						}],
						yAxes: [{
							gridLines: {
								display: false
							},
							ticks: {
								callback: function(value) {
									return (value.length < 45 ? value : value.substr(0, 45) + '...');
                  //return value.length < 50 ? value : value.substr(0, 50) + '\n' + value.substr(50);
								},
							}
						}]
					}
				}
			});

			let chart = myBarChart
			chart.options.animation = false;
		})

	}


	// Graphes douhnuts Satisfaction generale par meal dans /avis - review publique
	const all_doughnuts = document.querySelectorAll(".satisfaction-generale-meal-public-reviews");
	if (all_doughnuts) {
		Array.prototype.forEach.call(all_doughnuts, function (doughnut) {
			const satisfaction_generale_chart = new Chart(doughnut, {
				type: 'doughnut',
				data: {
					labels: ["Moyenne"],
					datasets: [{
						datalabels: {
							display: false,
						},
						data: JSON.parse(doughnut.dataset.points),

						backgroundColor: [
							'#5d78ff',
							'#e5e7f1',
						],

						hoverBackgroundColor: [
							'rgb(81,138,268)',
							'rgb(248,233,260)',
						],

						hoverBorderColor: [
							'rgb(81,138,268)',
							'rgb(248,233,260)',
						],
						borderWidth: 1
					}],

				},
				options: {
					cutoutPercentage: 80,
					legend: {
						display: false
					},
					tooltips: {
						enabled: false
					}
				}
			});

			let chart = satisfaction_generale_chart
			chart.options.animation = false;

		});

	}






	// DOUGHNUT CHART - QUESTIONNAIRES POUR LESQUELS LES CLIENTS ONT LAISSÉ OU NON LEURS COORDONNÉES - POUR TOUTES LES ENQUÊTES
	const doughnutQuestionnairesIdentifiesAnonymes = document.getElementById("questionnaires-identifies-anonymes");
	if (doughnutQuestionnairesIdentifiesAnonymes) {
		console.log("doughnutQuestionnairesIdentifiesAnonymes");
		doughnut_recompenses = new Chart(doughnutQuestionnairesIdentifiesAnonymes, {
			type: 'doughnut',
			data: {
				labels: JSON.parse(doughnutQuestionnairesIdentifiesAnonymes.dataset.points_em),
				datasets: [{
					data: JSON.parse(doughnutQuestionnairesIdentifiesAnonymes.dataset.points),
					backgroundColor: [
						'#5d78ff',
						'#e5e7f1',
					],

					hoverBackgroundColor: [
						'rgb(81,138,268)',
						'rgb(248,233,260)',
					],

					hoverBorderColor: [
						'rgb(81,138,268)',
						'rgb(248,233,260)',
					],
					borderWidth: 1
				}],
			},
			options: {
				cutoutPercentage: 80,
				legend: {
					position: 'bottom',
				},
				plugins: {
					datalabels: {
						formatter: (value, ctx) => {
							let sumu = 0;
							let dataArra = ctx.chart.data.datasets[0].data;
							dataArra.map(data => {
								sumu += data;
							});
							if (value > 0) {
								let percentages = (value * 100 / sumu).toFixed(0) + "%";
								return percentages;
							}
							else {
								return "";
							}
						},
						color: [
							'white',
							'black',
						],
					},
				},
			}
		});

		let chart = doughnut_recompenses
		chart.options.animation = false;
	};




	// BAR CHART - NB DE REWARDS DISTRIBUÉS ET NON DISTRIBUÉS (CLIENTS ANONYMES) PAR ENQUÊTE
	const chartRewardsPerEnquete = document.getElementById("rewards-per-enquete");
	if (chartRewardsPerEnquete) {
		console.log(chartRewardsPerEnquete)
		const chart_rewards_per_enquete = new Chart(chartRewardsPerEnquete, {
			type: 'bar',
			data: {
				labels: JSON.parse(chartRewardsPerEnquete.dataset.points_em),
				datasets: [{
					label: 'Identifiés',
					data: JSON.parse(chartRewardsPerEnquete.dataset.points_identified),
					backgroundColor: '#5d78ff',
					hoverBackgroundColor: 'rgb(81,138,268)',
				}, {
					label: 'Anonymes',
					data: JSON.parse(chartRewardsPerEnquete.dataset.points_anonymes),
					backgroundColor: '#e5e7f1',
					hoverBackgroundColor: 'rgb(248,233,260)',
				}],
			},
			options: {
				tooltips: {
					displayColors: true,
					callbacks: {
						mode: 'x',
					},
				},
				legend: {
					display: false
				},
				scales: {
					xAxes: [{
						stacked: true
					}],
					yAxes: [{
						ticks: {
							suggestedMin: 0
						},
						stacked: true,
						gridLines: {
							display: false,
						}
					}]
				},
			}
		});

		let chart = chart_rewards_per_enquete
		chart.options.animation = false;
	};
}

export {initChart};
