import { courbe_evolution_nps } from '../components/charts/courbe_evolution_nps'
import { courbe_evolution_csat } from '../components/charts/courbe_evolution_csat'

// Fetch specific elements on the page to load then asyncronously

function buildParams(groupId, enquete, enqueteRetraite, gq, secondId, allEnquete, timeframe, indicators, ranges, startDateString, endDateString, restoId, mono, range1, range2, triPertinence, triDate, triNote, category, mc, startDate, endDate, format, locale) {
  const params = [];
  if (secondId && secondId.length > 0) {
    params.push({ name: 'second_id', value: secondId });
  }
  if (enquete && enquete.length > 0) {
    enquete.forEach(e => params.push({ name: 'enquete', value: e.toLowerCase() }));
  }
  if (enqueteRetraite && enqueteRetraite.length > 0) {
    enqueteRetraite.forEach(e => params.push({ name: 'enquete_retraite', value: e }));
  }
  if (gq && gq.length > 0) {
    params.push({ name: 'gq', value: gq });
  }
  if (allEnquete) {
    params.push({ name: 'all_enquete', value: allEnquete });
  }
  if (format) {
    params.push({ name: 'format', value: format });
  }
  if (triPertinence) {
    params.push({ name: 'tri_pertinence', value: triPertinence });
  }
  if (triDate) {
    params.push({ name: 'tri_date', value: triDate });
  }
  if (triNote) {
    params.push({ name: 'tri_note', value: triNote });
  }
  if (category && category.length > 0) {
    params.push({ name: 'category', value: category });
  }
  if (mc && mc.length > 0) {
    params.push({ name: 'mc', value: mc });
  }
  if (groupId) {
    params.push({ name: 'group_id', value: groupId });
  }
  if (timeframe) {
    params.push({ name: 'timeframe', value: timeframe });
  }
  if (restoId) {
    params.push({ name: 'resto_id', value: restoId });
  }
  if (indicators) {
    Object.keys(indicators).forEach(key => {
      indicators[key].forEach(value => {
        params.push({ name: `indicators[${key}][]`, value: value });
      });
    });
  }
  if (ranges) {
    ranges.forEach(range => {
      params.push({ name: 'ranges[]', value: JSON.stringify(range) });
    });
  }
  if (startDateString) {
    params.push({ name: 'start_date_string', value: startDateString });
  }
  if (endDateString) {
    params.push({ name: 'end_date_string', value: endDateString });
  }
  if (startDate) {
    params.push({ name: 'start_date', value: startDate });
  }
  if (endDate) {
    params.push({ name: 'end_date', value: endDate });
  }
  if (mono) {
    params.push({ name: 'mono', value: mono });
  }
  if (range1) {
    params.push({ name: 'range1', value: JSON.stringify(range1) });
  }
  if (range2) {
    params.push({ name: 'range2', value: JSON.stringify(range2) });
  }
  if (locale) {
    params.push({ name: 'locale', value: locale });
  }
  return params;
}

async function buildUrl(base, params) {
  let url = base;
  if (params && params.length > 0) {
    const encodedParams = params.map(param => {
      if (['group_id', 'timeframe', 'start_date_string', 'end_date_string', 'mono', 'range1', 'range1_start', 'range1_end', 'range2_start', 'range2_end', 'start_date', 'end_date', 'locale'].includes(param.name)) {
        return `${encodeURIComponent(param.name)}=${param.value}`;
      } else if (['enquete', 'enquete_retraite'].includes(param.name)) {
        return `${encodeURIComponent(param.name)}[]=${encodeURIComponent(param.value)}`;
      } else {
        return `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`;
      }
    }).join('&');
    url += `?${encodedParams}`;
  }
  return url;
}

async function fetchAndUpdateElement(elementId, baseUrl, params) {
  const element = document.getElementById(elementId);
  if (element) {
    const url = await buildUrl(baseUrl, params);
    //console.log(`${url}`);

    try {
      const response = await fetch(url);
      const html = await response.text();

      element.innerHTML = html;
      console.log(`${elementId} fetch done`);
    } catch (error) {
      console.error(`Error loading ${elementId}:`, error);
    }
  }
}

function getCommonData() {
  const groupId = document.querySelector(".data-current-grupp").dataset.group;
  const restoId = JSON.parse(document.querySelector(".data-current-grupp").dataset.resto);
  const timeframe = document.querySelector(".data-current-grupp").dataset.timeframe;
  const indicators = JSON.parse(document.querySelector(".data-current-grupp").dataset.indicators);
  const ranges = JSON.parse(document.querySelector(".data-current-grupp").dataset.ranges);
  const startDateString = document.querySelector(".data-current-grupp").dataset.sds;
  const endDateString = document.querySelector(".data-current-grupp").dataset.eds;
  const startDate = document.querySelector(".data-current-grupp").dataset.sd;
  const endDate = document.querySelector(".data-current-grupp").dataset.ed;
  const mono = JSON.parse(document.querySelector(".data-current-grupp").dataset.mono);
  const range1 = JSON.parse(document.querySelector(".data-current-grupp").dataset.range1);
  const range2 = JSON.parse(document.querySelector(".data-current-grupp").dataset.range2);
  const locale = document.querySelector(".data-current-grupp").dataset.locale;

  const enqueteData = document.getElementById("enquete-data");
  const enquete = JSON.parse(enqueteData.getAttribute("data-enquete"));
  const enqueteRetraite = JSON.parse(enqueteData.getAttribute("data-enquete-retraite"));
  const gq = JSON.parse(enqueteData.getAttribute("data-gq"));
  const secondId = JSON.parse(enqueteData.getAttribute("data-second-id"));
  const allEnquete = JSON.parse(enqueteData.getAttribute("data-all-enquete"));
  const format = JSON.parse(enqueteData.getAttribute("data-format"));
  const triPertinence = JSON.parse(enqueteData.getAttribute("data-tri-pertinence"));
  const triDate = JSON.parse(enqueteData.getAttribute("data-tri-date"));
  const triNote = JSON.parse(enqueteData.getAttribute("data-tri-note"));
  const category = JSON.parse(enqueteData.getAttribute("data-category"));
  const mc = JSON.parse(enqueteData.getAttribute("data-mc"));

  return {
    groupId,
    enquete,
    enqueteRetraite,
    gq,
    secondId,
    allEnquete,
    timeframe,
    indicators,
    ranges,
    startDateString,
    endDateString,
    restoId,
    mono,
    range1,
    range2,
    triPertinence,
    triDate,
    triNote,
    category,
    mc,
    startDate,
    endDate,
    format,
    locale,
  };
}

async function fetchData(elementId, path, additionalLogic = null) {
  const commonData = getCommonData();
  const params = buildParams(...Object.values(commonData));

  await fetchAndUpdateElement(elementId, `/groups/${commonData.groupId}${path}`, params);
  if (additionalLogic) additionalLogic();
}

async function fetchDataCsatQuest(elementId, path, additionalLogic = null, itemContent, searchBy) {
  const commonData = getCommonData();
  const params = buildParams(...Object.values(commonData));

  params.push({ name: `item_${searchBy}`, value: JSON.stringify(itemContent) });

  await fetchAndUpdateElement(elementId, `/groups/${commonData.groupId}${path}`, params);
  if (additionalLogic) additionalLogic();
}

async function fetchDataNoteQuest(elementId, path, additionalLogic = null, resultEvolContent, searchBy) {
  const commonData = getCommonData();
  const params = buildParams(...Object.values(commonData));

  params.push({ name: `result_evol_${searchBy}`, value: JSON.stringify(resultEvolContent) });

  await fetchAndUpdateElement(elementId, `/groups/${commonData.groupId}${path}`, params);
  if (additionalLogic) additionalLogic();
}

async function fetchDataRankDistrib(elementId, path, questContent, searchBy, additionalLogic = null) {
  const commonData = getCommonData();
  const params = buildParams(...Object.values(commonData));

  params.push({ name: `quest_${searchBy}`, value: questContent });
  params.push({ name: `search_by_${searchBy}`, value: searchBy });

  await fetchAndUpdate(elementId, `/groups/${commonData.groupId}${path}`, params, commonData.groupId);
  if (additionalLogic) additionalLogic();
}

async function fetchDataSectionReponseOuverte(elementId, path, searchBy, additionalLogic = null) {
  const commonData = getCommonData();
  const params = buildParams(...Object.values(commonData));

  params.push({ name: `search_by_${searchBy}`, value: searchBy });

  await fetchAndUpdate(elementId, `/groups/${commonData.groupId}${path}`, params, commonData.groupId);
  if (additionalLogic) additionalLogic();
}

async function fetchCsatPerTheme() {
  await fetchDataJ('csat_per_theme', '/csat_per_theme');
}



async function fetchAndUpdate(elementId, baseUrl, params, groupId) {
  const element = document.getElementById(elementId);
  if (element) {
    const url = await buildUrl(baseUrl, params);

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'processing') {
      } else {
        element.innerHTML = data.html;
      }
    } catch (error) {
      console.error(`Error loading ${elementId}:`, error);
    }
  }
}

async function fetchDataJ(elementId, path, additionalLogic = null) {
  const commonData = getCommonData();
  const params = buildParams(...Object.values(commonData));

  await fetchAndUpdate(elementId, `/groups/${commonData.groupId}${path}`, params, commonData.groupId);
  if (additionalLogic) additionalLogic();
}

async function fetchTable() {
  await fetchDataJ('main_table', '/main_table');
}

async function fetchComments() {
  await fetchDataJ('index_gerant_comments', '/index_gerant_comments');
}

async function fetchRemarqueDgq() {
  await fetchDataJ('section_remarque_dgq', '/section_remarque_dgq');
}

async function fetchRankDistrib(searchBy, questContent) {
  await fetchDataRankDistrib(`ranking_distribution_${searchBy}`, '/ranking_distribution', questContent, searchBy);
}

async function fetchSectionReponseOuverte(searchBy) {
  await fetchDataSectionReponseOuverte(`section_reponse_ouverte_${searchBy}`, '/section_reponse_ouverte', searchBy);
}

async function fetchEvolNoteQuest(searchBy, resultEvolContent) {
  await fetchDataNoteQuest(`evol_note_quest_${searchBy}`, '/evol_note_quest', () => {
    courbe_evolution_csat();
  }, resultEvolContent, searchBy);
}

async function fetchEvolCsatQuest(searchBy, itemContent) {
  await fetchDataCsatQuest(`evol_csat_quest_${searchBy}`, '/evol_csat_quest', () => {
    courbe_evolution_csat();
  }, itemContent, searchBy);
}

async function fetchEvolNps() {
  console.log("fetchEvolNps");
  await fetchData('evol_nps_graph', '/evol_nps_graph', courbe_evolution_nps);
}

async function fetchEvolCsat() {
  console.log("fetchEvolCsat");
  await fetchData('evol_csat_graph', '/evol_csat_graph', courbe_evolution_csat);
}

async function fetchEvolCsatDgq() {
  await fetchData('evol_csat_chart_dgq', '/evol_csat_chart_dgq', courbe_evolution_csat);
}

async function fetchCompCsatDgq() {
  await fetchDataJ('csat_comparison_dgq', '/csat_comparison_dgq');
}

async function fetchTableNps() {
  await fetchDataJ('nps_table', '/nps_table');
}

async function fetchCommentsNps() {
  await fetchDataJ('nps_ig_comments', '/nps_ig_comments');
}

async function fetchTableCsat() {
  await fetchDataJ('csat_table', '/csat_table');
}

async function fetchTableCat() {
  await fetchData('csat_category', '/csat_category');
}

async function fetchTableMealItems() {
  await fetchData('csat_c', '/csat_c');
}

async function fetchTableMc() {
  await fetchData('csat_mc', '/csat_mc');
}

async function fetchTableComp() {
  await fetchDataJ('comparatif_table', '/comparatif_table');
}

async function fetchHourlyDistribDgq() {
  await fetchDataJ('hourly_distribution_dgq', '/hourly_distribution_dgq');
}

// This function calls each elements, for the INDEX GERANT page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_index_gerant(){
  const index_gerant_data = document.getElementById("index-gerant-view")
  if (index_gerant_data) {
    fetchCsatPerTheme();
    fetchTable();
    fetchComments();
    fetchEvolNps();
    fetchEvolCsat();
  }
}

// This function calls each elements, for the DETAIL GQ page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_detail_gq(){
  const detail_gq_data = document.getElementById("detail-gq-view")
  if (detail_gq_data) {
    fetchEvolCsatDgq();
    fetchRemarqueDgq();
    fetchCompCsatDgq();
    fetchTableCsat();
    fetchHourlyDistribDgq();
  }
}

// This function calls each elements, for the DASHBOARD TYPE QUESTION page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_dash_type_quest() {
  const dash_type_quest_data = document.getElementById("dash-type-quest-view");
  if (dash_type_quest_data) {
    const chartElements = document.querySelectorAll('.chat-element');
    chartElements.forEach(chartElement => {
      const searchBy = chartElement.dataset.sb;
      const questContent = chartElement.dataset.quest;
      fetchRankDistrib(searchBy, questContent);
    });
  }
}

// This function calls each elements, for the SECTION REPONSE OUVERTE page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_section_reponse_ouverte() {
  const section_reponse_ouverte_data = document.getElementById("section-reponse-ouverte-view");
  if (section_reponse_ouverte_data) {
    const chartElements = document.querySelectorAll('.reponse-ouverte-element');
    chartElements.forEach(chartElement => {
      const searchBy = chartElement.dataset.sb;
      fetchSectionReponseOuverte(searchBy);
    });
  }
}

// This function calls each elements, for the EVOL NOTE QUESTION page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_evol_note_quest() {
  const evol_note_quest_data = document.getElementById("evol-note-quest-view");
  if (evol_note_quest_data) {
    const chartElements = document.querySelectorAll('.char-element');
    chartElements.forEach(chartElement => {
      const searchBy = chartElement.dataset.sb;
      const resultEvolContent = chartElement.dataset.note;
      fetchEvolNoteQuest(searchBy, resultEvolContent);
    });
  }
}

// This function calls each elements, for the EVOL CSAT QUESTION page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_evol_csat_quest() {
  const evol_csat_quest_data = document.getElementById("evol-csat-quest-view");
  if (evol_csat_quest_data) {
    const chartElements = document.querySelectorAll('.chart-element');
    chartElements.forEach(chartElement => {
      const itemContent = chartElement.dataset.csat;
      const searchBy = chartElement.dataset.sb;
      fetchEvolCsatQuest(searchBy, itemContent);
    });
  }
}

// This function calls each elements, for the NPS INDEX GERANT page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_nps_index_gerant(){
  const nps_index_gerant_data = document.getElementById("nps-index-gerant-view")
  if (nps_index_gerant_data) {
    fetchTableNps();
    fetchCommentsNps();
    fetchEvolNps();
  }
}

// This function calls each elements, for the CATEGORY INDEX GERANT page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_category_index_gerant(){
  const category_index_gerant_data = document.getElementById("category-index-gerant-view")
  if (category_index_gerant_data) {
    fetchTableCat();
  }
}

// This function calls each elements, for the MULTISITE C page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_multisite_c(){
  const multisite_c_data = document.getElementById("multisite-c-view")
  if (multisite_c_data) {
    fetchTableMealItems();
  }
}

// This function calls each elements, for the MULTISITE MC page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_multisite_mc(){
  const multisite_mc_data = document.getElementById("multisite-mc-view")
  if (multisite_mc_data) {
    fetchTableMc();
  }
}

// This function calls each elements, for the COMPARATIF page
// when adding a new element, simply add it to this ft
export async function fetchAndUpdateElement_comparatif(){
  const comparatif_data = document.getElementById("comparatif-enquetes-view")
  if (comparatif_data) {
    fetchTableComp();
  }
}
