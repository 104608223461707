import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { userId: String }
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {
    // Vérifiez si l'abonnement existe déjà
    if (!this.constructor.subscription) {

      this.constructor.subscription = createConsumer().subscriptions.create(
        { channel: "MainTableChannel", user_id: this.userIdValue },
        { received: this.handleReceived.bind(this) }
      )
    }

  }

  handleReceived(data) {
    const tableDiv = document.getElementById("main_table") || document.getElementById("nps_table") || document.getElementById("csat_table") || document.getElementById("comparatif_table")
    if (tableDiv) {
      tableDiv.innerHTML = data.html
    }
  }
}
