import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"
import { hourlyDistrib } from '../components/hourly_distrib'


export default class extends Controller {
  static values = { userId: String }
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {
    // Vérifiez si l'abonnement existe déjà
    if (!this.constructor.subscription) {
      this.constructor.subscription = createConsumer().subscriptions.create(
        { channel: "HourlyGraphChannel", user_id: this.userIdValue },
        { received: this.handleReceived.bind(this) }
      )
    }
  }

  handleReceived(data) {
    const hourlyGraph = document.getElementById("hourly_distribution_dgq")
    if(hourlyGraph) {
      hourlyGraph.innerHTML = data.html
      hourlyDistrib();
    }
  }
}
