import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { userId: String, searchBy: String }
  // static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {

    // Call `createConsumer()` to get the consumer instance
    this.subscription = createConsumer().subscriptions.create(
      { channel: "SectionReponseOuverteChannel", user_id: this.userIdValue, search_by: this.searchByValue },
      {
        received: this.handleReceived.bind(this)
      }
    )
  }


  handleReceived(data) {
    const sectionReponseDiv = document.getElementById(`section_reponse_ouverte_${this.searchByValue}`)
    if(sectionReponseDiv) {
      sectionReponseDiv.innerHTML = data.html
    }
  }
}
