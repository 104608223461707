import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"
import { donught_csat_theme } from '../components/charts/donught_csat_theme'

export default class extends Controller {
  static values = { userId: String }
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {
    // Vérifiez si l'abonnement existe déjà
    if (!this.constructor.subscription) {
      this.constructor.subscription = createConsumer().subscriptions.create(
        { channel: "CsatPerThemeChannel", user_id: this.userIdValue },
        { received: this.handleReceived.bind(this) }
      )
    }
  }

  handleReceived(data) {
    const csatPerThemeDiv = document.getElementById("csat_per_theme")
    if(csatPerThemeDiv) {
      csatPerThemeDiv.innerHTML = data.html
      donught_csat_theme();
    }
  }
}
