import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"
import { comparaisonCsat } from '../components/comparison_csat'
import { chartComparisonCsat } from '../components/chart_comparison_csat'


export default class extends Controller {
  static values = { userId: String }
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {
    // Vérifiez si l'abonnement existe déjà
    if (!this.constructor.subscription) {
      this.constructor.subscription = createConsumer().subscriptions.create(
        { channel: "CsatCompChannel", user_id: this.userIdValue },
        { received: this.handleReceived.bind(this) }
      )
    }
  }

  handleReceived(data) {
    const csatCompDiv = document.getElementById("csat_comparison_dgq")
    if(csatCompDiv) {
      csatCompDiv.innerHTML = data.html
      comparaisonCsat();
      chartComparisonCsat();
    }
  }
}
