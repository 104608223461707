//imports

  import '../controllers'
	import "../components/polyfill";

	import { initChart } from "../plugins/init_chart.js";

	import "../plugins/flatpickr";
	import { dateChoice } from '../components/datepi';
	import { sortingActiveInactiveMealItems } from '../components/sorting_active_inactive_meal_items';
	import { previewImageOnFileSelectMeal } from "../components/photo_preview_new_meal";
	import { previewImageOnFileSelectCategory } from "../components/photo_preview_new_category";
	import { previewImageOnUpload } from "../components/photo_preview_on_upload";

	import "../components/onchangedate";
	import { showHideBar } from "../components/sidebar";
	import { checkboxesDisableEdit } from "../components/checkboxes-disable-edit";
	import { limitFileSizeUpload } from "../components/limit-file-size-upload";
	import { showHideBarMobile } from "../components/mobilesidebar";
	import { mobileScreenRemoveActiveIn } from "../components/mobile_screen_remove_active_in";
	import { changeArrow } from "../components/arrow";

	import {comparaison_theme_venu} from "../plugins/init_chart";
	import {rewards_per_enq} from "../plugins/init_chart";
	import {centerPercent} from "../components/center_percent";
	import {unhideMealMenuBoissons} from "../components/unhide-meal-menu-boissons";
	import "../components/mobilesidebar";
	import {restoInactifNoExistOne} from "../components/modal_resto_inactif_noexist";
	import {restoInactifNoExistTwo} from "../components/modal_resto_inactif_noexist";
	import {restoInactifNoExistThree} from "../components/modal_resto_inactif_noexist";
	import {restoPlatInactifNoExistOne} from "../components/modal_resto_inactif_noexist";
	import {restoPlatInactifNoExistTwo} from "../components/modal_resto_inactif_noexist";
	import {restoPlatInactifNoExistThree} from "../components/modal_resto_inactif_noexist";
	import {
		edit_questionnaire_url,
		edit_reward_concours,
		create_new_enquete,
		create_new_product,
		create_new_meal_all,
		edit_meal,
		edit_product,
		edit_meal_all,
		create_new_service,
		create_new_equipe,
		edit_service,
		create_new_category,
		create_new_master_category,
		create_new_master_category_all,
		edit_category,
		edit_master_category,
		edit_master_category_all,
		create_new_reward,
		create_new_reward_fid,
		create_new_reward_jeu_concours,
		edit_reward,
		edit_reward_fid,
		create_new_tag,
		create_new_start_button,
		edit_start_button,
		create_new_groupe_question,
		create_new_groupe_question_page,
		create_new_categ_all_multisite,
		create_new_claim,
		create_new_pub,
		edit_gp,
		create_new_restaurant,
		edit_restaurant,
		create_new_group,
		edit_question,
		edit_question_without_theme,
    select_date_to_delete_surveys,
		editMealDate,
		editMealDateAll,
		newMealDate,
		newMealItem,
		editContainerMealItem,
		editContainerMealItemAll,
		newContainerMealItem,
		edit_client_modal,
		new_user_form,
		edit_user_form,
    importDataBaseClients,
    CustomCreateClient,
		editEnquete} from "../components/modales_new_edit";

	import {backToTopBtnMenu} from "../components/back-to-top-btn";
	import {create_campaign} from "../components/js_for_campagne_mail";
	import {edit_campaign} from "../components/js_for_campagne_mail";
	import {display_clients_numbers_for_compaign} from "../components/js_for_campagne_mail";
	import {create_admin_mail} from "../components/js_for_create_admin_mail";

	import {collapseEditMasterCateg} from "../components/collapse_edit_mastercateg";

	import { modal_relance } from "../components/modal_reactivation";
	import { disapear_flashes } from "../components/flashes";
	import { shinkAndBrink } from "../components/navbar_on_queries";
	import { makeItGray } from "../components/alerts";
	import { cgu_check } from "../components/cgu_form";
	import { searchRestaurants } from "../components/search";
	import { searchRestaurantIndex } from "../components/search";
	import { searchCustomers } from "../components/search";
	import { searchCustomersrgpd } from "../components/search";
	import { searchCustomersbase } from "../components/search";
	import { searchScanMenuDigital } from "../components/search";
	import { searchSurveys } from "../components/search";
	import { searchLoginActivity } from "../components/search";
	import { searchUser } from "../components/search";
	import { checkboxFilter } from "../components/checkbox_filter_index_custo";
	import { checkboxFilterRestaurants } from "../components/checkbox_filter_index_custo";
	import { nestedDropdowns } from "../components/home_navbar_and_dropdowns";
	import { navbarTransition } from "../components/home_navbar_and_dropdowns";
	import { mobileDatepicker } from "../components/mobile_datepicker";
	import { sidebarTablet } from "../components/sidebar_tablet";
	import { mailPreview } from "../components/mail_preview";
	import { checkbox_switch } from "../components/checkbox_switch_reward_mail_fake";
	import { closeFlashes } from "../components/close_flashes";
	import { questionnaireJuste } from "../components/questionnaire_juste";
	import { alertEmail } from "../components/alerts_custom_email";
	import { allergensSelectionToggleCss } from "../components/allergens_selection";
	import { allergensSelectionEdit } from "../components/allergens_selection";
	import { tagsSelectionToggleCss } from "../components/meal_items_tags_selection";
	import { tagsSelectionEdit } from "../components/meal_items_tags_selection";
	import { pictoSelectionEdit } from "../components/meal_items_tags_selection";
	// import {displayForm} from "../components/display_form";
	// import {hideForm} from "../components/hide_form";
	// import {autoscroll} from "../components/autoscroll";
	import { openTableLink } from "../components/format_restaurants_surveys_path_link"

	import { initUpdateNavbarOnScroll } from '../components/navbar';
	import { previewImageOnFileSelect } from '../components/photo_preview_pub';
	import { previewImageOnFileSelectEnglish } from '../components/photo_preview_pub';
	import { previewImageOnFileSelectEdit } from '../components/photo_preview_pub';
	import { selectRightTheme } from '../components/select_right_theme_modal_gq';
	import { selectRightMasterCategMealResto } from '../components/select_right_theme_modal_gq';
	import { selectRightMasterCategProductEtablissement } from '../components/select_right_theme_modal_gq';
	import { selectRightCategProductetablissement } from '../components/select_right_theme_modal_gq';
	// import { selectRightMasterCategEditCategAllResto } from '../components/select_right_theme_modal_gq';
	import { selectRightCategMealResto } from '../components/select_right_theme_modal_gq';
	import { BtnsRadioNpsSurvey } from '../components/radio_btns_for_nps_survey';

	import { initSortableOnEnquete } from "../plugins/init_sortable";
	import { initSortableOnTags } from "../plugins/init_sortable";
	import { initSortableOnStartPage } from "../plugins/init_sortable";
	import { initSortableOnServeur } from "../plugins/init_sortable";
	import { initSortableOnRewardsFid } from "../plugins/init_sortable";
	import { initSortableOnRewardsDirect } from "../plugins/init_sortable";

	import { initSortableOnMasterCateg } from "../plugins/init_sortable";
	import { initSortableOnTagMeal } from "../plugins/init_sortable";
	import { initSortableOnTagService } from "../plugins/init_sortable";
	import { initSortableOnTagCategory } from "../plugins/init_sortable";
	import { initSortableOnQuestions } from "../plugins/init_sortable";
	import { initSortableOnGroupQuestions } from "../plugins/init_sortable";
	import { initSortableOnLinkedQuestions } from "../plugins/init_sortable";
	import { initSortableOnMealQuestions } from "../plugins/init_sortable";
	import { initSortableOnMealitems } from "../plugins/init_sortable";




	import { BtnValiderOrdreForDragDropMascat } from "../components/btn_valider_ordre_for_drag_and_drop";
	import { BtnValiderOrdreForDragDropMeal } from "../components/btn_valider_ordre_for_drag_and_drop";
	import { BtnValiderOrdreForDragDropCategory } from "../components/btn_valider_ordre_for_drag_and_drop";

	import { BtnValiderOrdreForAjaxActifAndInactifBtn } from "../components/btn_valider_ordre_for_drag_and_drop";
	import { validateBtnForEditPageSurvey } from "../components/validate_btn_in_edit_survey_and_edit_reclam";
	import { simulateFormNewEquipe } from "../components/simulate_form_new_service_equipe";
	import { btnCreateGroupAppearInRestaurantNew } from "../components/appear_btn_creation_group_in_restauraurant_new";
	import { btnCreateGroupAppearInRestaurantNewWithParams } from "../components/appear_btn_creation_group_in_restauraurant_new";
	import { reponseCommentaireToggleRightTiny } from "../components/reponse_commentaire_toggle_right_tiny";
	import { toggleTabsInCumulFidelite } from "../components/toggle_tabs_in_cumul_fidelite";
	import { surveyNewClientPageForBtnAutre } from "../components/survey_new_client_page_for_btn_autre";
	import { surveyNewClientPageForBtnAutreMobile } from "../components/survey_new_client_page_for_btn_autre";
	import { allJsForGroupeQuestionParametreQuestionVariable } from "../components/js_for_parametre_groupe_question";
	import { allJsForQuestionnaireNewSurveyV2 } from "../components/js_for_questionnaire_new_survey_v2";
	import { simulateBtnCheckBoxOnFiltersEnquete } from "../components/filter_on_enquete_on_dashboard_gq";
	import { displayBtnValidateWhenChangeFilter } from "../components/filter_on_enquete_on_dashboard_gq";
	import { getCountryCodeAndFlagForPhoneNumbers } from "../components/phone_number_input";
	// import { userCountryCodeAndFlagForPhoneNumbers } from "../components/phone_number_input";
	import { userEditCountryCodeAndFlagForPhoneNumbers } from "../components/phone_number_input";
	import { editCountryCodeAndFlagForPhoneNumbers } from "../components/phone_number_input";
	import { navbarDropdownCollapseIn } from "../components/navbar_dropdown_collapse_in";
	import { clickOnCategory } from "../components/link_on_card_autoscroll";
	import { clickOnThemeQuestion } from "../components/link_on_card_autoscroll";

	import { user_new_remove_choice } from "../components/users";
	import { programDateMeal } from '../components/program_date_meal';
	import { AddDateRangesForMeals } from '../components/add_date_ranges_for_meals';
	import { tabActiveNewMenu } from '../components/menu_digital_navbar';
	import { toggleInputQuantityForContainer } from '../components/js_for_contenants';
	import { customMenu } from '../components/custom_menu';
	import { customQuestionnaire } from '../components/custom_questionnaire';
  import { createRedirection } from '../components/create_redirections';
	import { showMaintenanceMess } from '../components/show_maintenance_message';
	import { alertRegexPhone } from '../components/alert_regex_phone_number';
	import { uncheckRadioBtns } from '../components/uncheck_radio_buttons';
	import { notClickableBtnForCreateStartPage, notClickableBtnForEditStartPage } from '../components/limited_characters_for_start_page';
  import { addDateField } from '../components/_show_hide_price_container';
	import { uncheckFieldAll } from '../components/_uncheckFieldAll';
  import { initializeHandleCmiPrice } from '../components/_handle_cmi_new_edit';
	import { alertNameMealItemsJs } from '../components/_alert_name_meal_items';
	import { fixPositionOnScroll } from '../components/_fix_position_review_answer_on_scroll';
	import { hoverAndCheckEmoji } from '../components/_hoverAndCheckEmoji';
	import { checkEmojiQuestionnaire } from '../components/_hoverAndCheckEmoji';
  import { toggleEmojiFilter } from '../components/_smiley_filter_on_click_survey';
	import { uncheckStars } from '../components/_starsCheck';
	import 'select2/dist/css/select2.css';
	import { initSelect2 } from 'plugins/init_select2';
	import { sendDraftOnInput } from '../components/_draft_auto_ajax';
	import { templatesSetVariables } from '../components/_templates_variables';
	import { chooseTemplate } from '../components/_choose_template_provider_page';
	// Javascript for filters on download_report & download_group_list.
	import { setNestedFilters } from '../components/filter_manager_download_report/_set_nested_filters';
	import { toggleSubmitBtnForFilters } from '../components/filter_manager_download_report/_toggle_submit_btn_for_filters';
	// Facebook popup login
	import { popupCenter } from 'components/popup_facebook_login';
	import { loadingDots } from '../components/_loading_dots';
	// JS for meals.html.erb | Multisite.
	import { renderInactiveMeals } from '../components/renderer/render_inactive_meals';
	import { renderSearchMeals } from '../components/renderer/render_search_meals';

	// import { navigationSelectionDisplay } from '../components/_ajax_sous_groupe_ancestry';
	// import { blockFromSubmissionOnEnter } from '../components/_ajax_sous_groupe_ancestry';
	// import { changeParentSgCheckbox } from '../components/_ajax_sous_groupe_ancestry';
	// import { navigateV6 } from '../components/_ajax_sous_groupe_ancestry';
	// import { highlightSearchNavBar } from '../components/_ajax_sous_groupe_ancestry';
	// import { collaspeDropDowns } from '../components/_ajax_sous_groupe_ancestry';
	import { loadNavbar } from '../components/_ajax_sous_groupe_ancestry';

	import { notationQuestionNewManager, notationQuestionCreatedManager } from '../components/parameter_questionnaire/_question_note_one_to_ten_manager';
	import { toggleDisableEnqueteFilter } from '../components/filter_manager/_enquetes_filter';
	import { toggleNestedSidebar } from '../components/_nested_sidebar_manager';
	import { comparisonCsat } from '../components/_ajax_comparison_csat';
  import { initializeCheckboxFilters } from '../components/_theme_filter.js';
	import { destroyEnquete } from '../components/_ajax_destroy_enquete';
	import { changeEmojiType } from '../components/_ajax_update_questions';
	import { toggleActiveEmojis } from '../components/_ajax_update_questions';
	import { ftUpdateTagStatus } from '../components/_ajax_update_questions';
	import { ftChangeOrderPossibleAnswers } from '../components/_ajax_update_questions';


	import { validationsForNewGroupQuestions } from '../components/_validations_for_btns';
	import { validationsForEditGroupQuestions } from '../components/_validations_for_btns';
	import { validationsForNewSurvey } from '../components/_validations_for_btns';
	import { validationsForEditSurvey } from '../components/_validations_for_btns';

import { checkboxFilteredDropdown } from '../components/_client_filter_new_quest';
import { dropdownDisableCheckbox } from '../components/_client_filter_new_quest';
import { dropdownDisableCheckboxRandom } from '../components/_client_filter_new_quest';

import {display_logos_in_modal} from "../components/restaurant_logo";
import {clear_uploaded_logo} from "../components/restaurant_logo";
import {clear_chosen_logo} from "../components/restaurant_logo";


import { destroyClientsFromDatabase } from '../components/_client_data_base';
import { checkboxCheckAllOthersCheckbox } from '../components/_client_data_base';
import { allCheckboxesFront } from '../components/_client_data_base';

import { toggleBtnForIndicatorsList } from '../components/_indicators';
import { scrollToAddIndicator } from '../components/_indicators';
import { selectedIndicatorFocus } from '../components/_indicators';
import { ajaxToDisplayIndicatorInfos } from '../components/_indicators';
import { updateIndicatorStatusAjax } from '../components/_indicators';
import { validationsForIndicators } from '../components/_indicators';
import { newIndicatorFormSubmission } from '../components/_indicators';
import { updateFrontAfterDeleteOrSubmit } from '../components/_indicators';
import { showConcernedSurveys } from '../components/_indicators';
import { formLanguagesDisplay } from '../components/_indicators';
import { addAndRemovePossibleAnswer } from '../components/_indicators';
import { displayPossibleAnswers } from '../components/_indicators';
import { updateNationalityAnswerPreview } from '../components/_indicators';
import { tooltipForEditQuestion } from '../components/_indicators';
import { toggleBtnEditSpecificEnqueteBoolean } from '../components/_indicators';

import { disableBtns } from '../components/_data_disable_with';

import{ setupPriceFormatButtons } from '../components/setup_price';
import { horizontalBarTransition, showTagDetails} from '../components/_dashboard_question_image.js'

import { fetchAndUpdateElement_index_gerant } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_detail_gq } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_nps_index_gerant } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_category_index_gerant } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_multisite_c } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_multisite_mc } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_dash_type_quest } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_section_reponse_ouverte } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_evol_note_quest } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_evol_csat_quest } from '../components/_fetch_and_update_elements'
import { fetchAndUpdateElement_comparatif } from '../components/_fetch_and_update_elements'
import { groupeQuestionDescription } from '../components/groupe_question_description'
//importsend


export function execJSextra() {
	$(".btn").removeClass("btn") // remove class .btn everywhere
	setTimeout(chooseTemplate, 0)
	// chooseTemplate();
	uncheckRadioBtns();
	edit_category();
	create_new_categ_all_multisite();
	create_new_enquete();
	create_new_product();
	create_new_meal_all();
	create_new_service();
	create_new_equipe();
	edit_service();
	create_new_master_category();
	create_new_category();
	create_new_reward();
	create_new_reward_fid();
	create_new_reward_jeu_concours();
	edit_reward_fid();
  edit_reward_concours();
	create_new_tag();
	create_new_groupe_question();
	create_new_groupe_question_page();
	create_new_claim();
	create_new_pub();
	edit_meal();
	edit_meal_all();
	edit_product();
	edit_reward();
	edit_gp();
	create_new_restaurant();
	edit_restaurant();
	create_new_group();
	create_new_master_category_all();
	edit_master_category();
	edit_master_category_all();
	edit_question();
	edit_question_without_theme();
  select_date_to_delete_surveys();
	create_new_start_button();
	edit_start_button();
	edit_client_modal();
	new_user_form();
	edit_user_form();
  edit_questionnaire_url();
	checkboxesDisableEdit();
	allergensSelectionToggleCss();
	allergensSelectionEdit();
	tagsSelectionToggleCss();
	editMealDate();
	editMealDateAll();
	newMealDate();
	newMealItem();
	editContainerMealItem();
	editContainerMealItemAll();
	newContainerMealItem();
  simulateBtnCheckBoxOnFiltersEnquete();
	displayBtnValidateWhenChangeFilter();
  showHideBar();
	showHideBarMobile();
	sidebarTablet();
	templatesSetVariables();
	setTimeout(mailPreview, 1000);
	initSortableOnMasterCateg();
	initSortableOnTags();
	initSortableOnServeur();
	initSortableOnRewardsDirect();
	initSortableOnRewardsFid();
	initSortableOnTagMeal();
	initSortableOnStartPage();
	initSortableOnEnquete();
	initSortableOnQuestions();
	initSortableOnLinkedQuestions();
	initSortableOnMealQuestions();
	initSortableOnGroupQuestions();
	initSortableOnTagService();
	initSortableOnTagCategory();
	initSortableOnMealitems();
	destroyClientsFromDatabase();
	checkboxCheckAllOthersCheckbox();
	allCheckboxesFront();
	toggleBtnForIndicatorsList();
	scrollToAddIndicator();
	selectedIndicatorFocus();
	ajaxToDisplayIndicatorInfos();
	updateIndicatorStatusAjax();
	validationsForIndicators();
	updateFrontAfterDeleteOrSubmit();
	showConcernedSurveys();
	formLanguagesDisplay();
	addAndRemovePossibleAnswer();
	displayPossibleAnswers();
	updateNationalityAnswerPreview();
	tooltipForEditQuestion();
	toggleBtnEditSpecificEnqueteBoolean();
	// getCountryCodeAndFlagForPhoneNumbers();
	// userCountryCodeAndFlagForPhoneNumbers();
	userEditCountryCodeAndFlagForPhoneNumbers();
	customMenu();
	reponseCommentaireToggleRightTiny();
	customQuestionnaire();
	editEnquete();
	allJsForGroupeQuestionParametreQuestionVariable();
	createRedirection();
	toggleActiveEmojis();
	disableBtns();
	programDateMeal();
  allJsForQuestionnaireNewSurveyV2();
  ftUpdateTagStatus();
	renderInactiveMeals();
	sortingActiveInactiveMealItems();
  toggleEmojiFilter();
  setupPriceFormatButtons();
	initSelect2();
  importDataBaseClients();
  CustomCreateClient();
  fetchAndUpdateElement_index_gerant();
  fetchAndUpdateElement_detail_gq();
  fetchAndUpdateElement_nps_index_gerant();
  fetchAndUpdateElement_category_index_gerant();
  fetchAndUpdateElement_multisite_c();
  fetchAndUpdateElement_multisite_mc();
  fetchAndUpdateElement_dash_type_quest();
  fetchAndUpdateElement_section_reponse_ouverte();
  fetchAndUpdateElement_evol_note_quest();
  fetchAndUpdateElement_evol_csat_quest();
  fetchAndUpdateElement_comparatif();
	groupeQuestionDescription();
}

export function execJSall() {
  horizontalBarTransition();
  showTagDetails();
	editCountryCodeAndFlagForPhoneNumbers();

	initChart();

	centerPercent();

	makeItGray();

	disapear_flashes();

	shinkAndBrink();

	initUpdateNavbarOnScroll();

	unhideMealMenuBoissons();

	changeArrow();

	modal_relance();

	limitFileSizeUpload();

	create_campaign();
	// setTimeout(create_campaign, 1000);

	edit_campaign();

	display_clients_numbers_for_compaign();

	create_admin_mail();

	// edit_pub();

	mobileScreenRemoveActiveIn();

	searchRestaurants();

	searchRestaurantIndex();

	searchCustomers();

	searchCustomersrgpd();

	searchCustomersbase();

	searchScanMenuDigital();

	searchSurveys();

	searchLoginActivity();

	searchUser();

	checkboxFilter();

	checkboxFilterRestaurants();

	checkbox_switch();

	navbarTransition();

	nestedDropdowns();

	mobileDatepicker();

	questionnaireJuste();

	closeFlashes();

	alertEmail();

	openTableLink();

	previewImageOnFileSelect();

	previewImageOnFileSelectEnglish();

	previewImageOnFileSelectEdit();

	selectRightTheme();

	selectRightMasterCategMealResto();

	selectRightMasterCategProductEtablissement();

	// selectRightMasterCategEditCategAllResto();

	backToTopBtnMenu();

	// selectRightCateg();

	selectRightCategMealResto();

	selectRightCategProductetablissement();

	BtnsRadioNpsSurvey();

	tagsSelectionEdit();

	pictoSelectionEdit();

	collapseEditMasterCateg();

	BtnValiderOrdreForDragDropMascat();

	BtnValiderOrdreForDragDropMeal();

	BtnValiderOrdreForDragDropCategory();

	BtnValiderOrdreForAjaxActifAndInactifBtn();

	validateBtnForEditPageSurvey();

	previewImageOnFileSelectMeal();

	previewImageOnFileSelectCategory();

	previewImageOnUpload();

	// ajaxOnTagsMenu();

	simulateFormNewEquipe();

	btnCreateGroupAppearInRestaurantNew();

	btnCreateGroupAppearInRestaurantNewWithParams();

	toggleTabsInCumulFidelite();

	restoInactifNoExistOne();

	restoInactifNoExistTwo();

	restoInactifNoExistThree();

	restoPlatInactifNoExistOne();

	restoPlatInactifNoExistTwo();

	restoPlatInactifNoExistThree();

	surveyNewClientPageForBtnAutre();

	surveyNewClientPageForBtnAutreMobile();

	navbarDropdownCollapseIn();

	clickOnCategory();

	clickOnThemeQuestion();

	toggleNestedSidebar();

	user_new_remove_choice();

	AddDateRangesForMeals();

	tabActiveNewMenu();

	toggleInputQuantityForContainer();

	showMaintenanceMess();

	alertRegexPhone();

	notClickableBtnForCreateStartPage();

	notClickableBtnForEditStartPage();

  initializeHandleCmiPrice();

  addDateField();

	uncheckFieldAll();

	alertNameMealItemsJs();

	fixPositionOnScroll();

	// removeCrisp();

	hoverAndCheckEmoji();

	checkEmojiQuestionnaire();

	uncheckStars();

	// Javascript for filters on download_report & download_group_list.
	setNestedFilters();
	toggleSubmitBtnForFilters();

	loadingDots();

	sendDraftOnInput();

	renderSearchMeals();

	notationQuestionNewManager();

	notationQuestionCreatedManager();

	toggleDisableEnqueteFilter();

	toggleNestedSidebar();

	comparisonCsat();

  initializeCheckboxFilters();

	changeEmojiType();

	destroyEnquete();

	validationsForNewGroupQuestions();

	validationsForEditGroupQuestions();

	validationsForNewSurvey();

	validationsForEditSurvey();

	ftChangeOrderPossibleAnswers();

	checkboxFilteredDropdown();


	dropdownDisableCheckbox();

	dropdownDisableCheckboxRandom();
	// logos de restaurants (new & edit)
	display_logos_in_modal();

	clear_uploaded_logo();

	clear_chosen_logo();

  newIndicatorFormSubmission();

}

export function execJSnav() {
	// navigateV6();
	// changeParentSgCheckbox();
	// highlightSearchNavBar();
	// navigationSelectionDisplay();
	// blockFromSubmissionOnEnter();
	// collaspeDropDowns();
	loadNavbar();
}
