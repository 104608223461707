import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { userId: String }
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {

    if (!this.constructor.subscription) {
      // Call `createConsumer()` to get the consumer instance
      this.constructor.subscription = createConsumer().subscriptions.create(
        { channel: "NpsCommentsChannel", user_id: this.userIdValue },
        {
          received: this.handleReceived.bind(this)
        }
      )
    }
  }

  handleReceived(data) {
    const npsCommentsDiv = document.getElementById("index_gerant_comments") || document.getElementById("nps_ig_comments")
    if (npsCommentsDiv) {
      npsCommentsDiv.innerHTML = data.html
    }
  }
}
